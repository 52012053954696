import * as R from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { defaultComponents } from '@cappex/search';
import StudentCollegeSearch from '@src/features/collegelist/components/StudentCollegeSearch';
import { FormNames, ListFormNames } from '@cappex/constants';
import useFormValidation from '../util/hooks/useFormValidation';
import {
	ADD_A_COLLEGE_MESSAGE,
	StudentCollegeConnectionType,
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListStatus,
	StudentCollegeListTrackingType,
	StudentCollegeListTypeTrackingValue,
} from '../util/studentcollege/constants';
import { SubFormContext } from './BaseValidationForm';
import { validateRequired } from './BaseFormSelect';
import SEARCH_INDEX_NAMES from '@util/search/constants';
import useCheckUrlCollegeId from '../util/hooks/useCheckUrlCollegeId';
import { CircularProgress, Grid } from '@material-ui/core';
import { styled } from '@cappex/theme';
import emptyStateSvg from '@common/assets/wamc/empty-state.svg';
import ConsentInput from '@src/features/consent/components/ConsentInput';
import { ConsentType } from '@src/features/consent/consentUtil';
import useCanObtainConsent from '../util/hooks/useCanObtainConsent';

const StyledImg = styled.img`
	width: 7rem;
	height: 7rem;
	margin: 4rem 0;
`;

const Padding = styled.div`
	padding-top: 2.5rem;
`;

type MultiCollegeInputProps = {
	active?: boolean;
	onSelect?: (collegeObject: object) => void;
	onRemove?: (collegeObject: object) => void;
	name?: ListFormNames | FormNames;
	required?: boolean;
	locationTrackingValue: StudentCollegeListLocationTrackingValue;
	typeTrackingValue: StudentCollegeListTypeTrackingValue;
	multiFormInit?: boolean;
	zindexInner?: number;
	extra?: {
		searchIndex?: string;
	};
};

const EMPTY_ARRAY = [];

type TrackingData = {
	[StudentCollegeListTrackingType.LOCATION]: StudentCollegeListLocationTrackingValue;
	[StudentCollegeListTrackingType.TYPE]: StudentCollegeListTypeTrackingValue;
};

const onChange = (
	trackingData: TrackingData,
	collegeObjects: object[],
	setCollegeObjects: (collegeObjects: object[]) => void,
	formValue: any,
	setFormValue: (v: any) => void,
	status: StudentCollegeListStatus,
	collegeObject: { value: string }
) => {
	const studentCollege = {
		collegeId: collegeObject.value,
		collegeListConnectionType: StudentCollegeConnectionType.GENERAL,
		collegeListStatus: status,
		levelOfInterest: null,
		collegeApplicationStatus: null,
		collegeListTrackingData: trackingData,
	};

	let currentStudentColleges = formValue[ListFormNames.studentColleges] || [];
	const oldStudentCollegeIndex = R.findIndex(
		R.propEq('collegeId', collegeObject.value),
		currentStudentColleges
	);

	if (oldStudentCollegeIndex !== -1) {
		currentStudentColleges[oldStudentCollegeIndex] = studentCollege;
	} else {
		currentStudentColleges = [...currentStudentColleges, studentCollege];
	}

	setFormValue({
		...formValue,
		[ListFormNames.studentColleges]: currentStudentColleges,
	});

	setCollegeObjects([
		...R.reject(R.propEq('collegeId', collegeObject.value), collegeObjects),
		studentCollege,
	]);
};

const MultiCollegeInput: FC<MultiCollegeInputProps> = ({
	active,
	onSelect = () => {},
	onRemove = () => {},
	locationTrackingValue,
	typeTrackingValue,
	name = ListFormNames.studentColleges,
	required,
	multiFormInit = false,
	zindexInner = 6,
	extra: { searchIndex } = { searchIndex: SEARCH_INDEX_NAMES.COLLEGE.valueOf() },
}) => {
	const {
		urlCollegeId,
		collegeId,
		collegeName,
		isCollegePending,
		isValidCollege,
	} = useCheckUrlCollegeId(active);
	const { path } = useContext(SubFormContext);
	const [collegeObjects, setCollegeObjects] = useState([]);
	const headerRef = useRef(null);
	const [alreadySetDefaultCollege, setAlreadySetDefaultCollege] = useState(false);
	const { consentResponse, loadingConsent } = useCanObtainConsent(
		ConsentType.INQUIRY_CONSENT,
		active
	);

	const initialValueObj = useMemo(
		() => ({
			[name]: EMPTY_ARRAY,
		}),
		[name]
	);
	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const { value, setValue } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: headerRef,
		multiFormInit,
	});

	const filterValues = useMemo(
		() =>
			R.pipe(
				R.without(collegeObjects || []),
				R.pluck('collegeId')
			)((value[ListFormNames.studentColleges] as string[]) || []),
		[collegeObjects, value]
	);

	const trackingData: TrackingData = useMemo(
		() => ({
			[StudentCollegeListTrackingType.LOCATION]: locationTrackingValue,
			[StudentCollegeListTrackingType.TYPE]: typeTrackingValue,
		}),
		[locationTrackingValue, typeTrackingValue]
	);

	const alreadyHasDefaultCollege = useMemo(
		() =>
			Array.isArray(value.studentColleges) &&
			value.studentColleges.some(
				studentCollege =>
					studentCollege.collegeId === urlCollegeId &&
					studentCollege.collegeListStatus === StudentCollegeListStatus.ACTIVE
			),
		[value.studentColleges, urlCollegeId]
	);

	const shouldSetDefaultCollege = useMemo(
		() => !isCollegePending && isValidCollege && active && !alreadyHasDefaultCollege,
		[isCollegePending, isValidCollege, active, alreadyHasDefaultCollege]
	);

	useEffect(() => {
		if (shouldSetDefaultCollege && !alreadySetDefaultCollege) {
			setAlreadySetDefaultCollege(true);
			const collegeObject = { value: collegeId };
			onChange(
				trackingData,
				collegeObjects,
				setCollegeObjects,
				value,
				setValue,
				StudentCollegeListStatus.ACTIVE,
				collegeObject
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- adding all deps creates an infinite loop
	}, [collegeId, shouldSetDefaultCollege, trackingData]);

	const defaultSelectedValues = useMemo(() => {
		const selectedValues = [];
		if (shouldSetDefaultCollege) {
			selectedValues.push({
				value: collegeId,
				label: collegeName,
			});
		}

		return selectedValues;
	}, [collegeId, collegeName, shouldSetDefaultCollege]);

	const hasActiveColleges = collegeObjects.some(
		studentCollege => studentCollege.collegeListStatus === StudentCollegeListStatus.ACTIVE
	);

	return (
		!isCollegePending && (
			<>
				<StudentCollegeSearch
					placeholderText={ADD_A_COLLEGE_MESSAGE}
					defaultSelectedValues={defaultSelectedValues}
					onItemSelect={collegeObject => {
						onChange(
							trackingData,
							collegeObjects,
							setCollegeObjects,
							value,
							setValue,
							StudentCollegeListStatus.ACTIVE,
							collegeObject
						);
						onSelect(collegeObject);
					}}
					onItemRemove={collegeObject => {
						onChange(
							trackingData,
							collegeObjects,
							setCollegeObjects,
							value,
							setValue,
							StudentCollegeListStatus.ARCHIVED,
							collegeObject
						);
						onRemove(collegeObject);
					}}
					transient={false}
					isMultiSelect
					stylingComponents={defaultComponents}
					disabled={
						collegeObjects.filter(
							college => college.collegeListStatus === StudentCollegeListStatus.ACTIVE
						).length >= 10
					}
					filterValues={filterValues}
					zindexInner={zindexInner}
					searchIndex={searchIndex}
				/>

				{!hasActiveColleges && (
					<Grid container justifyContent="center">
						<StyledImg src={emptyStateSvg} alt="empty state" />
					</Grid>
				)}

				{loadingConsent ? (
					<>
						<Padding />
						<Grid container justifyContent="center">
							<Grid item>
								<CircularProgress />
							</Grid>
						</Grid>
					</>
				) : (
					consentResponse?.canObtainConsent && (
						<>
							<Padding />
							<ConsentInput
								hasValidCorrespondingInput
								consentText={`👋 ${consentResponse.consentText}`}
								consentTextId={consentResponse.consentTextId}
								consentTypeId={consentResponse.consentTypeId}
								consentInputTypeId={consentResponse.consentInputTypeId}
								addInitialValueOnlyWhenActive
								active={active}
							/>
						</>
					)
				)}
			</>
		)
	);
};

export default MultiCollegeInput;
