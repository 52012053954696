import React, { FC, useContext, useState } from 'react';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import SingleCollegeInput from '@src/common/components/SingleCollegeInput';
import { StepContainerProps } from '@src/common/util/steps';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@src/common/util/studentcollege/constants';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import ChancesModal from '../ChancesModal';
import LegalTextAndLinks from '@common/components/LegalTextAndLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@cappex/theme';
import useCheckUrlCollegeId from '@src/common/util/hooks/useCheckUrlCollegeId';
import ConsentInput from '@src/features/consent/components/ConsentInput';
import { ConsentType } from '@src/features/consent/consentUtil';
import useCanObtainConsent from '@src/common/util/hooks/useCanObtainConsent';

const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const CHANCES_LANDING_MODAL_KEY = 'chances-landing-modal';

const RegisterButton = withStyleOptions(Button);

const FlexStepChancesLandingPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		buttonConfig,
		legalTextConfig,
	},
	complete,
	active,
	customLogoUrl,
}) => {
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { queueModal } = useContext(ModalContext);
	const {
		isCollegePending,
		collegeId,
		collegeName,
		setCollegeId,
		setCollegeName,
		isValidCollege,
	} = useCheckUrlCollegeId(active);

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const { consentResponse, loadingConsent } = useCanObtainConsent(
		ConsentType.INQUIRY_CONSENT,
		active
	);

	const onCollegeSelected = (collegeObject: any) => {
		setCollegeId(collegeObject.value);
		setCollegeName(collegeObject.label);
		setButtonDisabled(false);
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(false);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success && isValidCollege) {
				queueModal(QueueAction.PREPEND, CHANCES_LANDING_MODAL_KEY, ChancesModal, {
					name: collegeName,
					id: collegeId,
				});
				complete();
				setButtonDisabled(false);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
		});
		return true;
	};

	return (
		!isCollegePending && (
			<DataFlowContainer
				topMedia={topMedia}
				backgroundMedia={backgroundMedia}
				variant={variant}
				leftMedia={leftMedia}
				rightMedia={rightMedia}
				hideLeftMediaImageMobile={hideLeftMediaImageMobile}
				showLeftTextMediaMobile={showLeftTextMediaMobile}
				showRightTextMedia={showRightTextMedia}
				currentStep={currentStep}
				totalSteps={totalSteps}
				customLogoUrl={customLogoUrl}
			>
				<Grid container justifyContent="center" spacing={4}>
					<Grid item xs={12}>
						<SingleCollegeInput
							onSelect={onCollegeSelected}
							locationTrackingValue={StudentCollegeListLocationTrackingValue.CHANCES_TO_GET_IN}
							typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
							multiFormInit
							collegeId={collegeId}
							defaultCollegeName={collegeName}
							required
						/>
					</Grid>
					{loadingConsent ? (
						<CircularProgress />
					) : (
						consentResponse?.canObtainConsent && (
							<Grid item xs={12}>
								<ConsentInput
									hasValidCorrespondingInput
									consentText={`👋 ${consentResponse.consentText}`}
									consentTextId={consentResponse.consentTextId}
									consentTypeId={consentResponse.consentTypeId}
									consentInputTypeId={consentResponse.consentInputTypeId}
									addInitialValueOnlyWhenActive
									active={active}
								/>
							</Grid>
						)
					)}
					<Grid item xs={12}>
						<RegisterButton
							data-qa="flex-create-account-button"
							$noneTextTransform
							$boldFontWeight
							type="submit"
							variant="contained"
							color="primary"
							fullWidth
							disabled={isButtonDisabled || loadingConsent}
							onClick={onClick}
						>
							{buttonConfig?.text ?? 'Next'}
							{buttonConfig?.icon && (
								<PaddedBox>
									<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
								</PaddedBox>
							)}
						</RegisterButton>
					</Grid>
				</Grid>
				{legalTextConfig && <LegalTextAndLinks configs={legalTextConfig} />}
			</DataFlowContainer>
		)
	);
};

export default FlexStepChancesLandingPage;
