import React from 'react';
import { ConsentForm, ConsentInputType, ConsentType } from '../consentUtil';
import ConsentCheckboxAndText from '@src/common/components/ConsentCheckboxAndText';
import Disclaimer from './Disclaimer';

interface Props {
	consentInputTypeId?: ConsentInputType;
	consentTextId?: string;
	consentTypeId?: ConsentType;
	consentText?: string;
	hasValidCorrespondingInput?: boolean;
	allowNoResponse?: boolean;
	addInitialValueOnlyWhenActive?: boolean;
	active?: boolean;
	mode?: 'form' | 'direct';
	onSaveConsentDirectly?: (consentPayload: ConsentForm) => void;
}

const ConsentInput: React.FC<Props> = ({
	consentInputTypeId,
	consentTextId,
	consentTypeId,
	consentText,
	hasValidCorrespondingInput,
	allowNoResponse,
	addInitialValueOnlyWhenActive,
	active,
	mode = 'form',
	onSaveConsentDirectly,
}) => {
	switch (consentInputTypeId) {
		case ConsentInputType.NOT_CONFIGURABLE:
			return null;
		case ConsentInputType.DISCLAIMER:
			return (
				<Disclaimer
					consentTextId={consentTextId}
					consentTypeId={consentTypeId}
					consentText={consentText}
					addInitialValueOnlyWhenActive={addInitialValueOnlyWhenActive}
					active={active}
					mode={mode}
					onSaveConsentDirectly={onSaveConsentDirectly}
				/>
			);
		case ConsentInputType.CHECKBOX:
			return (
				<ConsentCheckboxAndText
					consentTextId={consentTextId}
					consentTypeId={consentTypeId}
					consentText={consentText}
					hasValidCorrespondingInput={hasValidCorrespondingInput}
					allowNoResponse={allowNoResponse}
					addInitialValueOnlyWhenActive={addInitialValueOnlyWhenActive}
					active={active}
					mode={mode}
					onSaveConsentDirectly={onSaveConsentDirectly}
				/>
			);
		default:
			return null;
	}
};

export default ConsentInput;
