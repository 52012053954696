import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import AddressBlock from '@src/common/components/AddressBlock';
import { SubForm } from '@src/common/components/BaseValidationForm';
import MajorFormInput from '@src/common/components/MajorFormInput';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames, ListFormNames } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import checkLockout from '@util/lockout';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import {
	Student,
	STUDENT_TYPE_OPTIONS,
	StudentType,
} from '@src/common/util/student/studentDataUtil';
import withStyleOptions from '@src/common/util/style/styleOptions';
import SizeFormComponent from '@src/features/collegepreferences/components/SizeFormComponent';
import LocationDistanceFormComponent from '@src/features/collegepreferences/components/LocationDistanceFormComponent';
import { FormContext, FormContextValue, FormFields } from '@src/common/util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import StudentTypeSelect, {
	StudentTypeSelectProps,
} from '@src/common/components/StudentTypeSelect';
import * as R from 'ramda';

type MatchesPreferences = DataFlowStepComponent<any, any> & StepContainerProps;

const MATCHES_STUDENT_TYPE_CHIP_COLUMNS: StudentTypeSelectProps['chipColumns'] = {};

const PageButton = withStyleOptions(Button);

const handleFailure = (
	json: any,
	setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) => {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setButtonDisabled(false);
		const formErrors: FormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
};

const MatchesPreferencesPage: FC<MatchesPreferences> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	complete,
	active,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic } = useContext(AuthContext);

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [userData] = useState<Partial<Student>>({});

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(false);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			} else {
				handleFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	useEffect(() => {
		if (
			(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
			active
		) {
			complete();
		}
	}, [active, complete, isAuthentic]);

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<StudentTypeSelect
						title="Join as a"
						defaultStudentTypeId={userData.studentTypeId || StudentType.HIGH_SCHOOL}
						options={STUDENT_TYPE_OPTIONS}
						isDefault={!R.isNil(userData?.studentTypeId)}
						chipColumns={MATCHES_STUDENT_TYPE_CHIP_COLUMNS}
					/>
				</Grid>
				<SubForm name="collegePreferences">
					<Grid item xs={12}>
						<SizeFormComponent
							label="What size college are you interested in?"
							name={ListFormNames.collegeSizeIds}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">Which majors are you interested in?</Typography>
						<MajorFormInput
							subText="Select up to 5"
							useOpenToAnyButton
							name={ListFormNames.majorCipCodes}
						/>
					</Grid>
					<Grid item xs={12}>
						<LocationDistanceFormComponent
							label="How far do you want to be from home?"
							name={FormNames.locationCollegePreference}
						/>
					</Grid>
				</SubForm>
				<Grid item xs={12}>
					<SubForm name="student">
						<SubForm name="studentAddressForm">
							<Typography variant="h6">Where is home?</Typography>
							<AddressBlock
								address1Label="Street Address"
								required={active}
								hideAddress2Input
								hideCityInput
								hideStateInput
								student={userData}
							/>
						</SubForm>
					</SubForm>
				</Grid>
				<Grid item xs={12}>
					<PageButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'See my matches' : 'Next'}
					</PageButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default MatchesPreferencesPage;
