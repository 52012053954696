import HiddenInput from '@common/components/HiddenInput';
import React, { FC } from 'react';
import { SubForm } from '@common/components/BaseValidationForm';
import mapDataFieldToFormLocation from '@src/features/dataflow/util/customQuizQuestionMapping';
import useDerivedStateFromPropsWithMap from '@util/hooks/useDerivedStateFromPropsWithMap';
import { StepConfiguration } from '@util/steps';
import { FormNames, ListFormNames } from '@cappex/constants';

const emptyObject = {};

interface HiddenInputProps {
	inputName: string;
	firstName?: FormNames[] | ListFormNames[];
	secondName?: FormNames[] | ListFormNames[];
}

interface CustomMappingProps {
	config: StepConfiguration<any>;
}

interface rendFormStructureCompProps {
	naming: (FormNames | ListFormNames)[];
	idx: number;
	inputName: string;
}

const RenderFormStructureComponents: React.FC<rendFormStructureCompProps> = ({
	naming,
	idx,
	inputName,
}) => {
	if (idx === naming.length - 1) {
		return (
			<HiddenInput
				name={naming[idx]}
				id={inputName}
				initialValue={emptyObject}
				automationName={`${inputName}-hidden-input`}
			/>
		);
	}
	return (
		<SubForm distinct name="student">
			<SubForm name={naming[idx]}>
				<RenderFormStructureComponents naming={naming} idx={idx + 1} inputName={inputName} />
			</SubForm>
		</SubForm>
	);
};

const createHiddenInput: FC<HiddenInputProps> = ({ inputName }) => {
	const naming = mapDataFieldToFormLocation(inputName);

	return (
		<div>
			<RenderFormStructureComponents naming={naming} idx={0} inputName={inputName} />
		</div>
	);
};

const FindAndCreateHiddenInput = (initialConfig: StepConfiguration<any>) => {
	const [config] = useDerivedStateFromPropsWithMap(initialConfig);
	const fieldsNeedingHiddenInputs: string[] = [];

	// Add all unique dataFields
	config.steps.forEach(step => {
		step.data.flexConfig &&
			step.data.flexConfig.forEach(flexStepConfig => {
				flexStepConfig.extra?.selectConfig?.forEach(sConfig => {
					const customMapping = sConfig?.customMapping;
					if (customMapping) {
						customMapping.forEach(mapping => {
							const { dataField } = mapping;

							if (dataField) {
								fieldsNeedingHiddenInputs.push(dataField);
							}
						});
					}
				});
			});
	});

	const uniqueFields = [...new Set(fieldsNeedingHiddenInputs)];

	return <>{uniqueFields.map(field => createHiddenInput({ inputName: field }))}</>;
};

const CustomMapping: FC<CustomMappingProps> = ({ config }) => FindAndCreateHiddenInput(config);

export default CustomMapping;
