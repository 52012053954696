import request, { JsonContentTypeHeader, RequestMethod, WebResponse } from '@cappex/request';

import getEndpoint from '@util/request';

export interface VisitorTypeToStudentTypeMappingResponse {
	visitorType: string;
	studentType: string;
}

const getVtStMapping = async () => {
	try {
		const vtStMappingResponse = await request<
			WebResponse<{}, VisitorTypeToStudentTypeMappingResponse[]>
		>({
			url: getEndpoint('/reference-data/v1/getAllVisitorTypeToStudentTypeMapping'),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});

		if (!vtStMappingResponse || !vtStMappingResponse.ok || !vtStMappingResponse.data.meta.success) {
			return null;
		}

		return vtStMappingResponse.data.response;
	} catch (err) {
		return null;
	}
};

export default getVtStMapping;
