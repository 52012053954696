import { useEffect, useState } from 'react';
import canObtainConsent from '@src/features/consent/utils/canObtainConsent';
import { CanObtainConsentResponse, ConsentType } from '@src/features/consent/consentUtil';

const useCanObtainConsent = (consentType: ConsentType, active?: boolean) => {
	const [consentResponse, setResponse] = useState<CanObtainConsentResponse>();
	const [loadingConsent, setLoadingConsent] = useState(true);

	useEffect(() => {
		if (active && !consentResponse?.canObtainConsent) {
			canObtainConsent(consentType, setResponse, () => setLoadingConsent(false));
		}
	}, [consentType, active, consentResponse?.canObtainConsent]);

	return {
		consentResponse,
		loadingConsent,
	};
};

export default useCanObtainConsent;
