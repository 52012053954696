import { createContext } from 'react';
import { PermissionsActionsMap } from '@util/user/userUtil';
import { RoleId } from '../roles/constants';

export enum AuthenticState {
	Authentic,
	NotAuthentic,
	Unknown,
}

export type AuthBase = {
	isAuthentic: AuthenticState;
	role: RoleId;
	permissionsAndActions: PermissionsActionsMap;
	registrationIsComplete: boolean;
	accountUuid: string;
};

export type Auth = AuthBase & {
	forceRefresh: (autoFail?: boolean, onAuthFail?: () => void) => Promise<void>;
	setValidAuth: (
		role: RoleId,
		permissionsAndActions: PermissionsActionsMap,
		registrationIsComplete: boolean,
		accountUuid: string
	) => void;
	setInvalidAuth: () => void;
	setUnknownAuth: () => void;
	attemptedLocation: string;
};

export const defaultAuthBase = {
	isAuthentic: AuthenticState.Unknown,
	role: null,
	permissionsAndActions: new Map(),
	registrationIsComplete: true,
	accountUuid: '',
};

const DEFAULT_FUNCTION = () => {
	throw new Error('Using default value for Auth');
};

export const defaultAuth: Auth = {
	...defaultAuthBase,
	forceRefresh: DEFAULT_FUNCTION,
	setValidAuth: DEFAULT_FUNCTION,
	setInvalidAuth: DEFAULT_FUNCTION,
	setUnknownAuth: DEFAULT_FUNCTION,
	attemptedLocation: '',
};

const AuthContext = createContext<Auth>(defaultAuth);

export default AuthContext;
