import * as R from 'ramda';
import { FormNames, ListFormNames } from '@cappex/constants';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';
import getEndpoint, { FormKeyedData } from '@util/request';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	WebResponse,
} from '@cappex/request';
import getLocalTrueDate from '../date';
import checkLockout from '@util/lockout';

interface StudentData {
	[FormNames.studentId]: string;
	[FormNames.birthDate]: string;
	[FormNames.firstName]: string;
	[FormNames.lastName]: string;
	[FormNames.address1]: string;
	[FormNames.address2]: string;
	[FormNames.city]: string;
	[FormNames.latitude]: string;
	[FormNames.longitude]: string;
	[FormNames.provinceRegion]: string;
	[FormNames.postalCode]: string;
	[FormNames.phone]: string;
	[FormNames.countryCode]: string;
}

export interface JsonStudentData extends StudentData {
	[FormNames.gender]: ReferenceData;
	[FormNames.religion]: ReferenceData;
	[FormNames.state]: ReferenceData;
	[FormNames.country]: ReferenceData;
}

export interface UpdateStudentDataForm {
	[FormNames.studentId]: string;
	[FormNames.birthDate]: string;
	[FormNames.updateBirthDate]: boolean;
	[FormNames.firstName]: string;
	[FormNames.updateFirstName]: boolean;
	[FormNames.lastName]: string;
	[FormNames.updateLastName]: boolean;
	[FormNames.phone]: string;
	[FormNames.updatePhone]: boolean;
	[FormNames.countryCode]: string;
	[FormNames.updateCountryCode]: boolean;
	[FormNames.genderId]: string;
	[FormNames.updateGenderId]: boolean;
	[FormNames.religionId]: string;
	[FormNames.updateReligionId]: boolean;
}

export interface UpdateStudentAddressForm {
	[FormNames.address1]: string;
	[FormNames.updateAddress1]: boolean;
	[FormNames.address2]: string;
	[FormNames.updateAddress2]: boolean;
	[FormNames.city]: string;
	[FormNames.updateCity]: boolean;
	[FormNames.provinceRegion]: string;
	[FormNames.updateProvinceRegion]: boolean;
	[FormNames.postalCode]: string;
	[FormNames.updatePostalCode]: boolean;
	[FormNames.countryId]: number;
	[FormNames.updateCountryId]: boolean;
	[FormNames.stateId]: number;
	[FormNames.updateStateId]: boolean;
}

export interface JsonStudentCollegeData {
	[FormNames.studentId]: string;
	[FormNames.collegeStartTermId]: string;
	[FormNames.collegeStartYear]: string;
	[FormNames.collegeGpa]: string;
	[FormNames.collegeGradMonth]: string;
	[FormNames.collegeGradYear]: string;
	[FormNames.collegeTransferTermId]: string;
	[FormNames.collegeTransferYear]: string;
	[FormNames.currentCollegeId]: string;
	[FormNames.openToTransfer]: boolean;
	[FormNames.firstGenerationStudent]: boolean;
}

export interface UpdateStudentCollegeDataForm extends JsonStudentCollegeData {
	[FormNames.updateCollegeStartTermId]: boolean;
	[FormNames.updateCollegeStartYear]: boolean;
	[FormNames.updateCollegeGpa]: boolean;
	[FormNames.updateCollegeGradMonth]: boolean;
	[FormNames.updateCollegeGradYear]: boolean;
	[FormNames.updateCollegeTransferTermId]: boolean;
	[FormNames.updateCollegeTransferYear]: boolean;
	[FormNames.updateCurrentCollegeId]: boolean;
	[FormNames.updateOpenToTransfer]: boolean;
	[FormNames.updateFirstGenerationStudent]: boolean;
}

export interface JsonStudentHighSchoolData {
	[FormNames.studentId]: string;
	[FormNames.highSchoolGradMonth]: string;
	[FormNames.highSchoolGradYear]: string;
	[FormNames.highSchoolId]: string;
	[FormNames.gpaUnweighted]: string;
	[FormNames.gpaWeighted]: string;
	[FormNames.classRank]: ReferenceData;
	[FormNames.courseRigor]: ReferenceData;
}

export interface UpdateStudentHighSchoolDataForm extends JsonStudentHighSchoolData {
	[FormNames.classRankId]: string;
	[FormNames.courseRigorId]: string;
	[FormNames.updateHighSchoolGradMonth]: boolean;
	[FormNames.updateHighSchoolGradYear]: boolean;
	[FormNames.updateHighSchoolId]: boolean;
	[FormNames.updateGpaUnweighted]: boolean;
	[FormNames.updateGpaWeighted]: boolean;
	[FormNames.updateClassRankId]: boolean;
	[FormNames.updateCourseRigorId]: boolean;
}

export interface JsonStudentFinancial {
	[FormNames.studentId]: string;
	[FormNames.annualFamilyIncomeLevel]: ReferenceData;
	[FormNames.interestedInFinancialAid]: boolean;
}

export interface UpdateStudentFinancialForm extends JsonStudentFinancial {
	[FormNames.annualFamilyIncomeLevelId]: string;
	[FormNames.updateAnnualFamilyIncomeLevelId]: boolean;
	[FormNames.updateInterestedInFinancialAid]: boolean;
}

export interface JsonStudentTestScore {
	[FormNames.studentId]: string;
	[FormNames.actComposite]: string;
	[FormNames.actMath]: string;
	[FormNames.actEnglish]: string;
	[FormNames.actReading]: string;
	[FormNames.actScience]: string;
	[FormNames.actWriting]: string;
	[FormNames.satComposite]: string;
	[FormNames.satReadWrite]: string;
	[FormNames.satMath]: string;
	[FormNames.psatNmsqt]: string;
	[FormNames.preAct]: string;
}

export interface UpdateStudentTestScoreForm extends JsonStudentTestScore {
	[FormNames.updateSatComposite]: boolean;
	[FormNames.updateSatReadWrite]: boolean;
	[FormNames.updateSatMath]: boolean;
	[FormNames.updateActComposite]: boolean;
	[FormNames.updateActMath]: boolean;
	[FormNames.updateActEnglish]: boolean;
	[FormNames.updateActReading]: boolean;
	[FormNames.updateActScience]: boolean;
	[FormNames.updateActWriting]: boolean;
	[FormNames.updatePsatNmsqt]: boolean;
	[FormNames.updatePreAct]: boolean;
}

export type JsonStudentEthnicities = ReferenceData[];

export interface UpdateStudentEthnicities {
	[ListFormNames.studentEthnicities]: number[];
	[FormNames.updateStudentEthnicities]: boolean;
}

export interface UpdateStudentAthleticForm {
	[ListFormNames.studentAthletics]: string[];
	[FormNames.updateStudentEthnicities]: boolean;
}

export interface UpdateStudentExtracurricularForm {
	[FormNames.studentEthnicitiesForm]: string[];
	[FormNames.updateStudentEthnicities]: boolean;
}

export interface JsonStudentInfo {
	[ListFormNames.modalityIds]: ReferenceData[];
	[FormNames.startTimeframeId]: ReferenceData;
	[FormNames.desiredCompletionTimeframeId]: ReferenceData;
	[FormNames.levelOfDegreeSeekingId]: ReferenceData;
	[FormNames.lastDegreeCompletedId]: ReferenceData;
	[FormNames.enrolled]: boolean;
	[FormNames.seekingMba]: boolean;
	[FormNames.seekingTestOptionalAdmissions]: boolean;
	[FormNames.yearsOfWorkExperience]: number;
}

export interface JsonStudentAthleticForm {
	[ListFormNames.studentAthletics]: ReferenceData[];
}

export interface JsonStudentExtracurricularForm {
	[ListFormNames.studentExtracurriculars]: ReferenceData[];
}

export interface UpdateStudentInfoForm {
	[ListFormNames.modalityIds]: number[];
	[FormNames.updateModalityIds]: boolean;
	[FormNames.startTimeframeId]: number;
	[FormNames.updateStartTimeframeId]: boolean;
	[FormNames.desiredCompletionTimeframeId]: number;
	[FormNames.updateDesiredCompletionTimeframeId]: boolean;
	[FormNames.levelOfDegreeSeekingId]: number;
	[FormNames.updateLevelOfDegreeSeekingId]: boolean;
	[FormNames.lastDegreeCompletedId]: number;
	[FormNames.updateLastDegreeCompletedId]: boolean;
	[FormNames.studyAbroadId]: string;
	[FormNames.updateStudyAbroadId]: boolean;
	[FormNames.enrolled]: boolean;
	[FormNames.updateEnrolled]: boolean;
	[FormNames.seekingMba]: boolean;
	[FormNames.updateSeekingMba]: boolean;
	[FormNames.seekingTestOptionalAdmissions]: boolean;
	[FormNames.updateSeekingTestOptionalAdmissions]: boolean;
	[FormNames.yearsOfWorkExperience]: number;
	[FormNames.updateYearsOfWorkExperience]: boolean;
}

export type UpdateStudentForm = {
	[FormNames.studentId]: string;
	[FormNames.studentTypeId]: string;
	[FormNames.updateStudentTypeId]: boolean;
	[FormNames.studentDataForm]: Partial<UpdateStudentDataForm>;
	[FormNames.studentAddressForm]: Partial<UpdateStudentAddressForm>;
	[FormNames.studentCollegeDataForm]: Partial<UpdateStudentCollegeDataForm>;
	[FormNames.studentHighSchoolDataForm]: Partial<UpdateStudentHighSchoolDataForm>;
	[FormNames.studentFinancialForm]: Partial<UpdateStudentFinancialForm>;
	[FormNames.studentTestScoreForm]: Partial<UpdateStudentTestScoreForm>;
	[FormNames.studentEthnicitiesForm]: Partial<UpdateStudentEthnicities>;
	[FormNames.studentInfo]: Partial<UpdateStudentInfoForm>;
};

export type JsonStudentForm = {
	[FormNames.id]: string;
	[FormNames.studentTypeId]: string;
	[ListFormNames.studentCbos]: string[];
	[FormNames.studentData]: JsonStudentData;
	[FormNames.studentCollegeData]: JsonStudentCollegeData;
	[FormNames.studentHighSchoolData]: JsonStudentHighSchoolData;
	[FormNames.studentFinancial]: JsonStudentFinancial;
	[FormNames.studentTestScore]: JsonStudentTestScore;
	[ListFormNames.studentEthnicities]: JsonStudentEthnicities;
	[FormNames.studentInfo]: JsonStudentInfo;
	[FormNames.studentAthleticForm]: JsonStudentAthleticForm;
	[FormNames.studentExtracurricularForm]: JsonStudentExtracurricularForm;
};

export type StudentDataUpdateProps = {
	onUpdateStudentData?: (studentFieldKey: string, dataToUpdate: any) => any;
	onUpdateStudent?: (student: Partial<Student>) => any;
};

export type UpdateStudentWebResponse = WebResponse<FormKeyedData, JsonStudentForm>;

export const updateStudentData = (
	updateStudentForm: Partial<UpdateStudentForm>,
	onSuccess: (jsonResponse: JsonStudentForm) => void,
	onError: (json: UpdateStudentWebResponse) => void,
	onCatchError: () => void,
	onFailedAuth: () => void = () => {}
) => {
	request<UpdateStudentWebResponse>({
		url: getEndpoint('/student/v3/update'),
		method: RequestMethod.POST,
		data: updateStudentForm,
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(response => {
			if (!R.isNil(response)) checkLockout(response);

			return response;
		})
		.then(data => (data.meta.success ? onSuccess(data.response) : onError(data)))
		.catch(err => {
			if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
				onFailedAuth();
				return;
			}
			onCatchError();
		});
};

export enum StudentType {
	HIGH_SCHOOL = '1',
	COLLEGE = '2',
	NOT_IN_SCHOOL = '3',
	OTHER = '4',
}

export enum VisitorType {
	HS_STUDENT = '1',
	HS_GRAD = '2',
	XFER_STUDENT = '3',
	COLLEGE_GRAD = '4',
	ADULT_LEARNER = '5',
	PARENT_HS_STUDENT = '6',
	PARENT_HS_GRAD = '7',
	PARENT_XFER_STUDENT = '8',
	PARENT_COLLEGE_GRAD = '9',
	VISITOR = '10',
	CURRENT_STUDENT = '11',
}

export const VISITOR_TYPE_OPTIONS = {
	HS_STUDENT: { displayValue: 'Current High School Student', value: VisitorType.HS_STUDENT },
	HS_GRAD: { displayValue: 'High School Graduate', value: VisitorType.HS_GRAD },
	XFER_STUDENT: { displayValue: 'Transfer College Student', value: VisitorType.XFER_STUDENT },
	COLLEGE_GRAD: { displayValue: 'College Graduate', value: VisitorType.COLLEGE_GRAD },
	ADULT_LEARNER: { displayValue: 'Adult Learner', value: VisitorType.ADULT_LEARNER },
	PARENT_HS_STUDENT: {
		displayValue: 'Parent of High School Student',
		value: VisitorType.PARENT_HS_STUDENT,
	},
	PARENT_HS_GRAD: {
		displayValue: 'Parent of High School Graduate',
		value: VisitorType.PARENT_HS_GRAD,
	},
	PARENT_XFER_STUDENT: {
		displayValue: 'Parent of Transfer College Student',
		value: VisitorType.PARENT_XFER_STUDENT,
	},
	PARENT_COLLEGE_GRAD: {
		displayValue: 'Parent of College Graduate',
		value: VisitorType.PARENT_COLLEGE_GRAD,
	},
	VISITOR: { displayValue: 'General Visitor', value: VisitorType.VISITOR },
	CURRENT_STUDENT: { displayValue: 'Current College Student', value: VisitorType.CURRENT_STUDENT },
};

export const STUDENT_TYPE_OPTIONS = [
	{ displayValue: 'High school student', value: StudentType.HIGH_SCHOOL },
	{ displayValue: 'College student', value: StudentType.COLLEGE },
	{ displayValue: 'Adult Learner', value: StudentType.NOT_IN_SCHOOL },
	{ displayValue: 'Other', value: StudentType.OTHER },
];

export const compareStudentType = (
	studentType: StudentType,
	targetStudentType: StudentType
): boolean => {
	const hsOrOther = [StudentType.HIGH_SCHOOL, StudentType.OTHER];
	if (targetStudentType === StudentType.HIGH_SCHOOL) {
		if (hsOrOther.includes(studentType)) {
			return true;
		}
	}
	return targetStudentType === studentType;
};

export enum LevelOfDegreeId {
	ASSOCIATES = 1,
	BACHELORS = 2,
	MASTERS = 3,
	DOCTORATE = 4,
	TRADE = 5,
}

const StudentTypeReverseBinding = R.invertObj(StudentType);
export const getStudentType = (val: string): StudentType =>
	StudentType[StudentTypeReverseBinding[val]];

export type Student = Omit<
	JsonStudentData,
	FormNames.birthDate | FormNames.country | FormNames.state | FormNames.gender
> &
	JsonStudentCollegeData &
	Omit<JsonStudentHighSchoolData, FormNames.classRank | FormNames.courseRigor> &
	Omit<JsonStudentFinancial, FormNames.annualFamilyIncomeLevel> &
	Omit<JsonStudentTestScore, FormNames.id> & {
		[FormNames.studentTypeId]: StudentType;
		[ListFormNames.studentCbos]: string[];
		[FormNames.birthDay]: string;
		[FormNames.birthMonth]: string;
		[FormNames.birthYear]: string;
		[FormNames.annualFamilyIncomeLevel]: string;
		[FormNames.annualFamilyIncomeLevelId]: string;
		[FormNames.country]: string;
		[FormNames.countryId]: string;
		[FormNames.state]: string;
		[FormNames.stateId]: number;
		[FormNames.gender]: string;
		[FormNames.genderId]: string;
		[FormNames.religion]: string;
		[FormNames.religionId]: string;
		[ListFormNames.studentEthnicities]: string[];
		[FormNames.classRank]: string;
		[FormNames.classRankId]: string;
		[FormNames.courseRigor]: string;
		[FormNames.courseRigorId]: string;
		[ListFormNames.modalityIds]: string[];
		[FormNames.startTimeframe]: string;
		[FormNames.startTimeframeId]: number;
		[FormNames.desiredCompletionTimeframe]: string;
		[FormNames.desiredCompletionTimeframeId]: number;
		[FormNames.levelOfDegreeSeeking]: string;
		[FormNames.levelOfDegreeSeekingId]: LevelOfDegreeId;
		[FormNames.lastDegreeCompletedId]: number;
		[FormNames.enrolled]: boolean;
		[FormNames.seekingMba]: boolean;
		[FormNames.seekingTestOptionalAdmissions]: boolean;
		[FormNames.yearsOfWorkExperience]: number;
		[FormNames.attendReligiousId]: string;
		[FormNames.studyAbroadId]: string;
		[ListFormNames.studentAthletics]: string[];
		[ListFormNames.studentExtracurriculars]: string[];
	};

export const EMPTY_STUDENT = {
	studentId: undefined,
	studentTypeId: undefined,
	state: undefined,
	annualFamilyIncomeLevel: undefined,
	gpaUnweighted: undefined,
	actComposite: undefined,
	satComposite: undefined,
} as Student;

const getReferenceValue = (obj: any, fieldName: FormNames) =>
	obj && obj[fieldName] && obj[fieldName].value;

const getReferenceId = (obj: any, fieldName: FormNames) =>
	obj && obj[fieldName] && obj[fieldName].id;

const getReferenceIds = (obj: any, fieldName: ListFormNames) =>
	obj && Array.isArray(obj[fieldName]) && obj[fieldName].map((item: any) => item.id);

const testScoreFieldsToConvert = [
	FormNames.actComposite,
	FormNames.actMath,
	FormNames.actEnglish,
	FormNames.actReading,
	FormNames.actScience,
	FormNames.actWriting,
	FormNames.satComposite,
	FormNames.satReadWrite,
	FormNames.satMath,
	FormNames.psatNmsqt,
	FormNames.preAct,
];

const highSchoolDataFieldsToConvert = [
	FormNames.highSchoolGradMonth,
	FormNames.highSchoolGradYear,
	FormNames.gpaUnweighted,
	FormNames.gpaWeighted,
];

const collegeDataFieldsToConvert = [
	FormNames.collegeStartTermId,
	FormNames.collegeStartYear,
	FormNames.collegeGpa,
	FormNames.collegeGradMonth,
	FormNames.collegeGradYear,
	FormNames.collegeTransferTermId,
	FormNames.collegeTransferYear,
	FormNames.currentCollegeId,
];

export const convertObjectFieldsToString = <T extends Record<string, any>>(
	obj: T,
	fieldNames: FormNames[]
): T =>
	(R.mapObjIndexed(
		(value, key: string) =>
			R.and(R.includes(key, fieldNames), !R.isNil(value)) ? String(value) : value,
		obj
	) as unknown) as T;

export const parseStudent = (jsonStudentData: JsonStudentForm): Student => {
	const birthDate =
		jsonStudentData.studentData.birthDate === null
			? null
			: getLocalTrueDate(jsonStudentData.studentData.birthDate);

	// Replaced by birthDay, birthMonth, and birthYear
	const {
		[FormNames.birthDate]: birthDateStr,
		...filteredStudentData
	} = jsonStudentData.studentData;

	return {
		...filteredStudentData,
		...convertObjectFieldsToString(jsonStudentData.studentCollegeData, collegeDataFieldsToConvert),
		...convertObjectFieldsToString(
			jsonStudentData.studentHighSchoolData,
			highSchoolDataFieldsToConvert
		),
		...jsonStudentData.studentFinancial,
		...convertObjectFieldsToString(jsonStudentData.studentTestScore, testScoreFieldsToConvert),
		[FormNames.studentId]: String(jsonStudentData.id),
		[FormNames.studentTypeId]: getStudentType(jsonStudentData.studentTypeId),
		[ListFormNames.studentCbos]: jsonStudentData.studentCbos,
		[FormNames.birthDay]: birthDate === null ? null : birthDate.getDate().toString(),
		[FormNames.birthMonth]: birthDate === null ? null : birthDate.getMonth().toString(),
		[FormNames.birthYear]: birthDate === null ? null : birthDate.getFullYear().toString(),
		[FormNames.annualFamilyIncomeLevel]: getReferenceValue(
			jsonStudentData.studentFinancial,
			FormNames.annualFamilyIncomeLevel
		),
		[FormNames.annualFamilyIncomeLevelId]: getReferenceId(
			jsonStudentData.studentFinancial,
			FormNames.annualFamilyIncomeLevel
		),
		[FormNames.country]: getReferenceValue(jsonStudentData.studentData, FormNames.country),
		[FormNames.countryId]: getReferenceId(jsonStudentData.studentData, FormNames.country),
		[FormNames.state]: getReferenceValue(jsonStudentData.studentData, FormNames.state),
		[FormNames.stateId]:
			Number(getReferenceId(jsonStudentData.studentData, FormNames.state)) || undefined,
		[FormNames.gender]: getReferenceValue(jsonStudentData.studentData, FormNames.gender),
		[FormNames.genderId]: getReferenceId(jsonStudentData.studentData, FormNames.gender),
		[FormNames.religion]: getReferenceValue(jsonStudentData.studentData, FormNames.religion),
		[FormNames.religionId]: getReferenceId(jsonStudentData.studentData, FormNames.religion),
		[FormNames.attendReligiousId]: getReferenceId(
			jsonStudentData.studentInfo,
			FormNames.attendReligiousId
		),
		[FormNames.studyAbroadId]: getReferenceId(jsonStudentData.studentInfo, FormNames.studyAbroadId),
		[ListFormNames.studentAthletics]: getReferenceIds(
			jsonStudentData,
			ListFormNames.studentAthletics
		),
		[ListFormNames.studentExtracurriculars]: getReferenceIds(
			jsonStudentData,
			ListFormNames.studentExtracurriculars
		),
		[ListFormNames.studentEthnicities]: jsonStudentData.studentEthnicities.map(
			ethnicity => ethnicity.id
		),
		[FormNames.classRank]: getReferenceValue(
			jsonStudentData.studentHighSchoolData,
			FormNames.classRank
		),
		[FormNames.classRankId]: getReferenceId(
			jsonStudentData.studentHighSchoolData,
			FormNames.classRank
		),
		[FormNames.courseRigor]: getReferenceValue(
			jsonStudentData.studentHighSchoolData,
			FormNames.courseRigor
		),
		[FormNames.courseRigorId]: getReferenceId(
			jsonStudentData.studentHighSchoolData,
			FormNames.courseRigor
		),
		[FormNames.startTimeframe]: getReferenceValue(
			jsonStudentData.studentInfo,
			FormNames.startTimeframeId
		),
		[FormNames.startTimeframeId]:
			Number(getReferenceId(jsonStudentData.studentInfo, FormNames.startTimeframeId)) || undefined,
		[FormNames.desiredCompletionTimeframe]: getReferenceValue(
			jsonStudentData.studentInfo,
			FormNames.desiredCompletionTimeframeId
		),
		[FormNames.desiredCompletionTimeframeId]:
			Number(getReferenceId(jsonStudentData.studentInfo, FormNames.desiredCompletionTimeframeId)) ||
			undefined,
		[FormNames.levelOfDegreeSeeking]: getReferenceValue(
			jsonStudentData.studentInfo,
			FormNames.levelOfDegreeSeekingId
		),
		[FormNames.lastDegreeCompletedId]:
			Number(getReferenceId(jsonStudentData.studentInfo, FormNames.lastDegreeCompletedId)) ||
			undefined,
		[FormNames.enrolled]: jsonStudentData.studentInfo[FormNames.enrolled],
		[FormNames.yearsOfWorkExperience]: jsonStudentData.studentInfo[FormNames.yearsOfWorkExperience],
		[FormNames.seekingMba]: jsonStudentData.studentInfo[FormNames.seekingMba],
		[FormNames.seekingTestOptionalAdmissions]:
			jsonStudentData.studentInfo[FormNames.seekingTestOptionalAdmissions],
		[FormNames.levelOfDegreeSeekingId]:
			Number(getReferenceId(jsonStudentData.studentInfo, FormNames.levelOfDegreeSeekingId)) ||
			undefined,
		[ListFormNames.modalityIds]: jsonStudentData.studentInfo.modalityIds.map(
			modalityValues => modalityValues.id
		),
	};
};
