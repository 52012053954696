import React, { FC, useContext, useMemo, useState } from 'react';
import checkLockout from '@util/lockout';
import { Box, Grid, Typography } from '@material-ui/core';
import { SubForm } from '@src/common/components/BaseValidationForm';

import AddressBlock from '@src/common/components/AddressBlock';
import HiddenInput from '@src/common/components/HiddenInput';
import RegisterButton from '@src/common/components/RegisterButton';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { DataFlowContainerVariant, FormNames } from '@cappex/constants';
import {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	RequestSourceIdentifier,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import { FormContext } from '@util/validation/form';
import { USA_COUNTRY_ID } from '@src/common/constants/referenceData';
import { styled } from '@cappex/theme';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import NameForm from '@src/common/components/NameForm';
import BirthDateInput from '@src/common/components/BirthDateInput';
import StudentTypeSelect from '@src/common/components/StudentTypeSelect';
import { STUDENT_TYPE_OPTIONS, StudentType } from '@src/common/util/student/studentDataUtil';
import { useNavigate } from 'react-router-dom';

const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;
const SmallGutterBottomTypography = styled(Typography)`
	margin-bottom: 0.5rem;
`;

const AlrScholarshipInfoPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: { topMedia, currentStep, totalSteps },
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const navigate = useNavigate();

	const [submitDisabled, setSubmitDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openErrorSnack(errors[FORM_NAME]);
				}
			}
		});
		return true;
	};

	const studentTypeFunctions = useMemo(
		() => [
			{
				studentType: StudentType.COLLEGE,
				function: () => navigate('/register/scholarship/info'),
			},
			{
				studentType: StudentType.HIGH_SCHOOL,
				function: () => navigate('/register/scholarship/info'),
			},
		],
		[navigate]
	);

	return (
		<DataFlowContainer
			variant={DataFlowContainerVariant.CENTERED}
			topMedia={topMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<SmallGutterBottomTypography variant="h6">I&apos;m currently</SmallGutterBottomTypography>
					<StudentTypeSelect
						title=""
						options={STUDENT_TYPE_OPTIONS}
						studentTypeFunctions={studentTypeFunctions}
						defaultStudentTypeId={StudentType.NOT_IN_SCHOOL}
						chipColumns={{ xs: 6 }}
						gutter
						isDefault
					/>
				</Grid>
				<SubForm name="student">
					<SubForm name="studentDataForm">
						<Grid item>
							<GutterBottomTypography variant="h6">Name</GutterBottomTypography>
							<Grid container spacing={1}>
								<NameForm id="NameForm" fullWidth variant="outlined" />
							</Grid>
						</Grid>
						<Grid item>
							<BirthDateInput
								id="registration__birth_date"
								required
								label="Birthday"
								variant="outlined"
							/>
						</Grid>
					</SubForm>
					<Grid item>
						<Typography variant="h6">Where are you coming from?</Typography>
						<GutterBottomTypography variant="body2" color="textSecondary">
							We give you custom recommendations based on where you live.
						</GutterBottomTypography>
						<Grid container spacing={1}>
							<SubForm name="studentAddressForm">
								<HiddenInput name={FormNames.countryId} initialValue={USA_COUNTRY_ID} />
								<AddressBlock
									address1Label="Street Address"
									required={active}
									hideAddress2Input
									hideCityInput
									hideStateInput
									hideCountryInput
									student={{}}
									inputStyle="outlined"
								/>
							</SubForm>
						</Grid>
					</Grid>
					<Grid item>
						<RegisterButton md={12} submitDisabled={submitDisabled} onClick={onClick}>
							Next
							<PaddedBox>
								<FontAwesomeIcon icon={faArrowRight} />
							</PaddedBox>
						</RegisterButton>
					</Grid>
				</SubForm>
			</Grid>
		</DataFlowContainer>
	);
};

export default AlrScholarshipInfoPage;
