import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import LoadingComponent from '@src/common/components/LoadingComponent';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import OfferInterstitialModal from '@src/features/offer/containers/OfferInterstitialModal';
import request, {
	ErrorMessages,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
} from '@cappex/request';
import useCampaignOffers from '@util/hooks/useCampaignOffers';
import getEndpoint from '@util/request';
import { getRegistrationInterstitialPlacementByPathname } from '@src/features/offer/util/offerUtil';
import { FormContext } from '@src/common/util/validation/form';
import { JitEvent } from '@src/common/util/jit/constants';
import { DataFlowStepComponent } from '../../constants/types';
import { StepContainerProps } from '@src/common/util/steps';
import { QuizContext, Result } from '@util/quiz';
import { DataFlowType, FormNames } from '@cappex/constants';
import EventBus, { EventTypes } from '@src/features/app/util/EventBus';
import StudentContext from '@src/common/util/studentContext';

const OFFER_INTERSTITIAL_MODAL_KEY = 'offer-interstitial-modal';

const useFinalizeDataFlow = (
	requestData: {
		dataFlowCode: any;
		dataFlowVersion: any;
	},
	onSuccess: () => void,
	setError: (isError: boolean) => void,
	getCurrentResult: () => Result,
	saveResults: boolean,
	metaBucketId: string
) => {
	const requestBody = {
		completeDataFlow: true,
		...requestData,
		metaPostbackBucketId: metaBucketId,
	};
	const quizIdentifier = `${requestData.dataFlowCode}.${requestData.dataFlowVersion}`;

	let newForm = requestBody;

	if (saveResults && getCurrentResult()) {
		newForm = R.assocPath(
			['student', FormNames.studentQuizResultForm, FormNames.quizResults],
			{ [quizIdentifier]: getCurrentResult().header },
			requestBody
		);
	}

	return useCallback(async () => {
		const res = await request<any>({
			url: getEndpoint('/data-flow/v1/submit-data'),
			method: RequestMethod.POST,
			data: newForm,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		});
		if (res?.data?.meta?.success) {
			onSuccess();
		} else {
			setError(true);
		}
	}, [onSuccess, setError, newForm]);
};

const useDataFlowFormValues = () => {
	const { getValue } = useContext(FormContext);

	const { dataFlowCode } = getValue('dataFlowCode') as {
		dataFlowCode: string;
	};
	const { dataFlowVersion } = getValue('dataFlowVersion') as {
		dataFlowVersion: string;
	};
	const { studentTypeId } = getValue('studentTypeId') as { studentTypeId: string };
	const { studentColleges } = getValue('studentColleges') as { studentColleges: any[] };

	const { eventId } = getValue('eventId') as { eventId: string };
	const { tourDataExternalId } = getValue('tourDataExternalId') as { tourDataExternalId: string };

	const value = useMemo(
		() => ({
			dataFlowCode,
			dataFlowVersion,
			studentColleges,
			eventId,
			tourDataExternalId,
			studentTypeId,
		}),
		[dataFlowCode, dataFlowVersion, studentColleges, eventId, tourDataExternalId, studentTypeId]
	);

	return value;
};

const FinalizeStep: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	data: { skipLoading },
	dataFlowType,
	saveResults,
	metaBucketId,
}) => {
	const { pathname } = useLocation();
	const { queueModal } = useContext(ModalContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { getCurrentResult } = useContext(QuizContext);
	const [isError, setIsError] = useState(false);
	const formValues = useDataFlowFormValues();
	const [mounted, setMounted] = useState(false);
	const [ranFinalize, setRanFinalize] = useState(false);
	const { student, refreshStudent, loading } = useContext(StudentContext);

	const onCampaignOfferError = useCallback(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	}, [openSnackbar]);

	const interstitialPlacement = useMemo(
		() => getRegistrationInterstitialPlacementByPathname(pathname),
		[pathname]
	);

	const [campaignOffers, isCampaignOffersReady] = useCampaignOffers(
		interstitialPlacement,
		onCampaignOfferError
	);

	const queueOfferInterstitialModal = useCallback(() => {
		if (!R.isNil(campaignOffers) && !R.isEmpty(campaignOffers)) {
			queueModal(QueueAction.PREPEND, OFFER_INTERSTITIAL_MODAL_KEY, OfferInterstitialModal, {
				placement: interstitialPlacement,
				campaignOffers,
			});
		}
	}, [queueModal, campaignOffers, interstitialPlacement]);

	const onSuccessfulFinalize = useCallback(() => {
		const collegeList = formValues?.studentColleges;

		if (collegeList && collegeList.length > 0) {
			EventBus.emit(EventTypes.JIT_CHECK, {
				eventType: JitEvent.COLLEGE_INQUIRY,
				collegeIds: collegeList.map(item => item.collegeId),
			});
		}

		if (dataFlowType === DataFlowType.REGISTRATION) {
			EventBus.emit(EventTypes.JIT_CHECK, {
				eventType: JitEvent.REGISTRATION_COMPLETION,
				dataFlowId: `${formValues.dataFlowCode}.${formValues.dataFlowVersion}`,
			});

			queueOfferInterstitialModal();
		}

		complete();
	}, [
		queueOfferInterstitialModal,
		complete,
		formValues.dataFlowCode,
		formValues.dataFlowVersion,
		formValues.studentColleges,
		dataFlowType,
	]);

	const finalize = useFinalizeDataFlow(
		formValues,
		onSuccessfulFinalize,
		setIsError,
		getCurrentResult,
		saveResults,
		metaBucketId
	);

	// Once we've got everything lined up, try to Finalize automatically
	useEffect(() => {
		const completeFinalizeStep = async () => {
			let { studentId } = student;
			if (!studentId && !loading) {
				const newStudent = await refreshStudent();
				studentId = newStudent.studentId;
			}

			const completedRegField = `completedRegistration-${studentId}`;

			if (studentId && isCampaignOffersReady && !isError && !skipLoading && !ranFinalize) {
				setRanFinalize(true);
				// Don't finalize if we've already completed registration. We have to use browser storage and
				// can't use react state because navigating backwards by popstate (right clicking the back
				// button and clicking an item in history) resets the state
				if (
					dataFlowType === DataFlowType.REGISTRATION &&
					sessionStorage.getItem(completedRegField)
				) {
					complete();
					return;
				}
				sessionStorage.setItem(completedRegField, 'true');
				finalize();
			}
		};
		completeFinalizeStep();
	}, [
		loading,
		isError,
		isCampaignOffersReady,
		finalize,
		skipLoading,
		complete,
		student,
		dataFlowType,
		refreshStudent,
		ranFinalize,
	]);

	useEffect(() => {
		if (mounted) return;
		setMounted(true);
		if (skipLoading) {
			finalize();
			complete();
		}
	}, [finalize, complete, skipLoading, mounted]);

	return <LoadingComponent error={isError} retry={finalize} />;
};

export default FinalizeStep;
