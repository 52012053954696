import { TextField, TextFieldProps } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import useFormValidation from '../util/hooks/useFormValidation';
import { DataFlowStepComponent } from '@src/features/dataflow/constants/types';
import { StepContainerProps } from '../util/steps';
import { FormNames, ListFormNames } from '@cappex/constants';
import { AutomationNameDefault } from '../util/automation';
import request, {
	WebResponse,
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import { TourPersonalization as TourPersonalizationType } from '../constants/types';
import AuthContext, { AuthenticState } from '../util/auth';
import requiredFieldMessage from '../util/validation/constants';
import { ConsentType } from '@src/features/consent/consentUtil';
import ConsentInput from '@src/features/consent/components/ConsentInput';
import styled from 'styled-components';
import useCanObtainConsent from '../util/hooks/useCanObtainConsent';
import useUrlParam from '../util/hooks/useUrlParam';

const MarginBottomTextField = styled(TextField)`
	margin-bottom: 1rem;
`;

const name = ListFormNames.studentTourPersonalizationForms;
const path = ['student'];
const initialValue = { [name]: [] };

const TourPersonalization: React.FC<DataFlowStepComponent<any, any> &
	StepContainerProps &
	TextFieldProps> = ({ active, variant, required }) => {
	const controlRef = React.useRef(null);
	const personalizationPreferenceRef = React.useRef(null);
	const [loadingTourPersonalization, setLoadingTourPersonalization] = React.useState(false);
	const [latch, setLatch] = React.useState(false);
	const { isAuthentic } = useContext(AuthContext);
	const { consentResponse, loadingConsent } = useCanObtainConsent(ConsentType.TOUR_AI, active);
	const tourLocation = useUrlParam('tourLocation');

	const validator = useCallback(
		formValue => {
			if (required && !formValue[name]?.[0]?.personalizationPreference) {
				return requiredFieldMessage;
			}
			return '';
		},
		[required]
	);

	// This hook is used to store the form value. Separate hooks are used for
	// validation errors since validating an array of objects is tricky with our
	// current setup
	const { value, setValue, error: validationError } = useFormValidation({
		path,
		name,
		fieldRef: controlRef,
		initialValue,
		validator,
	});

	// The following hook is used for validation errors
	const { error } = useFormValidation({
		name: `${FormNames.personalizationPreference}[0]`,
		fieldRef: personalizationPreferenceRef,
		removeUpdateFields: true,
	});

	const handleChange = useCallback(
		(personalizationPreference: string) => {
			setValue({
				[name]:
					typeof personalizationPreference === 'string'
						? [{ personalizationPreference, tourLocation }]
						: null,
			});
		},
		[setValue, tourLocation]
	);

	const getStudentTourPersonalization = useCallback(async () => {
		try {
			setLoadingTourPersonalization(true);
			const { data } = await request<
				WebResponse<FormKeyedData, { personalizationPreference: string; tourLocation: string }>
			>({
				url: getEndpoint(
					`/student/v1/retrieve/tourPersonalization`,
					tourLocation ? [{ name: 'tourLocation', value: tourLocation }] : undefined
				),
				method: RequestMethod.GET,
				withCredentials: true,
				headers: [JsonAcceptHeader, JsonContentTypeHeader],
			});
			handleChange(data.response.personalizationPreference);
		} finally {
			setLoadingTourPersonalization(false);
		}
	}, [handleChange, tourLocation]);

	useEffect(() => {
		if (active && !latch && isAuthentic === AuthenticState.Authentic) {
			setLatch(true);
			getStudentTourPersonalization();
		}
	}, [active, latch, isAuthentic, getStudentTourPersonalization]);

	return (
		<>
			<MarginBottomTextField
				id={name}
				ref={personalizationPreferenceRef}
				name={name}
				required={required}
				disabled={loadingTourPersonalization || loadingConsent}
				fullWidth
				variant={variant}
				error={!!error || !!validationError}
				helperText={error || validationError}
				value={(value[name] as TourPersonalizationType[])?.[0]?.personalizationPreference || ''}
				onChange={e => handleChange(e.target.value)}
				InputProps={{
					multiline: true,
					required: true,
					rows: 4,
					inputProps: {
						placeholder: 'Show me the dorms, student life, and biology labs',
						'data-qa': AutomationNameDefault.tourPersonalization,
						style: { lineHeight: '1.5' },
					},
				}}
			/>
			{consentResponse?.canObtainConsent && (
				<ConsentInput
					consentText={consentResponse.consentText}
					consentTextId={consentResponse.consentTextId}
					consentTypeId={consentResponse.consentTypeId}
					consentInputTypeId={consentResponse.consentInputTypeId}
					hasValidCorrespondingInput
					addInitialValueOnlyWhenActive
					active={active}
				/>
			)}
		</>
	);
};

export default TourPersonalization;
