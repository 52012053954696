import React, { FC, useMemo, useRef, useContext } from 'react';
import * as R from 'ramda';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';
import useFormValidation from '@util/hooks/useFormValidation';
import {
	FormControl,
	FormLabel,
	Typography,
	FormControlLabel,
	Checkbox,
	FormHelperText,
} from '@material-ui/core';
import { AutomationNameGeneric } from '../util/automation';
import { FormFields } from '../util/validation/form';
import { SubFormContext } from './BaseValidationForm';
import requiredFieldMessage from '../util/validation/constants';

export interface BaseFormCheckboxProps {
	id: string;
	name: string;
	label: string;
	reference: ReferenceData;
	initialValue?: string[];
	automationName?: string;
	required?: boolean;
}

const defaultInitialValue = [];

export const validateRequired = (name: string, required: boolean) => (input: FormFields) => {
	const value = input[name];

	if (required && (R.isNil(value) || R.isEmpty(value))) {
		return requiredFieldMessage;
	}

	return '';
};

const BaseFormCheckbox: FC<BaseFormCheckboxProps> = ({
	id,
	name,
	label,
	reference,
	initialValue = defaultInitialValue,
	automationName = AutomationNameGeneric.checkbox,
	required = false,
}) => {
	const { path } = useContext(SubFormContext);
	const listRef = useRef(null);

	const initialValueObj = useMemo(() => ({ [name]: initialValue }), [initialValue, name]);

	const validator = useMemo(() => validateRequired(name, required), [name, required]);

	const { value, setValue, error } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: listRef,
	});

	const onCheckboxSelect = (checkboxId: string) => () => {
		const selectedItems = value[name] as string[];
		let newItems: string[];

		const index = R.findIndex(R.equals(checkboxId), selectedItems);
		if (index >= 0) {
			newItems = R.remove(index, 1, selectedItems);
		} else {
			newItems = R.append(checkboxId, selectedItems);
		}

		setValue({ [name]: newItems });
	};

	return (
		<FormControl margin="normal" fullWidth variant="outlined" error={!!error} ref={listRef}>
			<FormLabel>
				<Typography variant="h6" display="inline" color="textPrimary">
					{label}
				</Typography>
			</FormLabel>
			<FormControlLabel
				control={
					<Checkbox
						id={`${id}-${reference.id}`}
						checked={R.includes(reference.id, value[name] || [])}
						onChange={onCheckboxSelect(reference.id)}
						value={reference.id}
						data-qa={`${automationName}-${reference.id}`}
						required={required}
					/>
				}
				label={reference.value}
				key={reference.id}
				htmlFor={`${id}-${reference.id}`}
			/>
			<FormHelperText>{error}</FormHelperText>
		</FormControl>
	);
};

export default BaseFormCheckbox;
