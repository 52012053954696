import * as R from 'ramda';
import { ContentBlock } from '@common/constants/contentBlock';
import { EMPTY_REFERENCE, ReferenceData } from '@util/hooks/useCloudReferenceData';
import { USA_COUNTRY_ID } from '@src/common/constants/referenceData';
import getLocalTrueDate from '../date';

export enum CollegeAssetsKey {
	COLLEGE_HERO = 'COLLEGE_HERO',
	COLLEGE_LOGO = 'COLLEGE_LOGO',
	COLLEGE_VERTICAL_IMAGE = 'COLLEGE_VERTICAL_IMAGE',
	COLLEGE_SCHOOL_LOGO = 'COLLEGE_SCHOOL_LOGO',
}

export enum CollegeSizeShort {
	VERY_SMALL = 'Very Small',
	SMALL = 'Small',
	MID_SIZE = 'Mid Size',
	LARGE = 'Large',
	VERY_LARGE = 'Very Large',
}

export type College = {
	ipedsId: number;
	name: string;
	city: ReferenceData;
	country: ReferenceData;
	state: ReferenceData;
	zipCode: ReferenceData;
	tagline?: string;
	redirectUrl?: string;
};

export type CollegeAsset = {
	id: number;
	collegeId: number;
	externalFilePath: string;
	identifier: string;
	collegeAssetTypeEnum: CollegeAssetsKey;
	attributionRequired: boolean;
	author?: string;
	attribution?: string;
	edited?: boolean;
	sourceLink?: string;
};

export type CollegeAssets = {
	[CollegeAssetsKey.COLLEGE_LOGO]: CollegeAsset;
	[CollegeAssetsKey.COLLEGE_HERO]: CollegeAsset;
	[CollegeAssetsKey.COLLEGE_VERTICAL_IMAGE]: CollegeAsset;
	[CollegeAssetsKey.COLLEGE_SCHOOL_LOGO]: CollegeAsset[];
};

export type CollegeAdmission = {
	averageOverallAcceptanceRate: number;
	admittedStudentAverageGpa: number;
	admittedStudentAverageAct: number;
	admittedStudentAverageSat: number;
	admittedStudentsAct25thPercentile: number;
	admittedStudentsAct75thPercentile: number;
	admittedStudentsNewSatMath25thPercentile: number;
	admittedStudentsNewSatMath75thPercentile: number;
	admittedStudentsNewSatEbrw25thPercentile: number;
	admittedStudentsNewSatEbrw75thPercentile: number;
	rollingAdmissions: boolean;
	regularDecisionFallApplicationDeadline: Date;
	earlyDecisionApplicationDeadline: Date;
	earlyActionApplicationDeadline: Date;
	testScoresOptional: boolean;
	satSubjectTestsRequired: boolean;
	highSchoolTranscriptRequired: boolean;
	lettersOfRecommendationsRequired: boolean;
	supplementalEssayPersonalStatementRequirement: boolean;
	interviewRequirement: boolean;
	applicationFeeWaiverOffered: boolean;
	undergraduateApplicationFee: number;
	hasOwnHomegrownApplication: boolean;
	acceptsCoalitionApplication: boolean;
	acceptsCommonApplication: boolean;
	acceptsStateSpecificApplicationSystem: boolean;
	acceptsUniversalCollegeApplication: boolean;
	easyApplyUrl: string;
	transferPortalUrl: string;
};

export type CollegeInformationalServiceResponse = {
	institutionType: ReferenceData;
	levelOfInstitution: ReferenceData;
	townSize: ReferenceData;
	religiousAffiliation: ReferenceData;
	coedStatus: ReferenceData;
	collegeSizeLong: string;
	collegeSizeShort: string;
	historicallyBlackCollege: boolean;
	hispanicServingInstitution: boolean;
	hasYouVisitVirtualTour: boolean;
	campusReelActive: boolean;
	urlForCampusReel: string;
};

// keep separate from service response for future fields
export type CollegeInformational = {
	institutionType: ReferenceData;
	levelOfInstitution: ReferenceData;
	townSize: ReferenceData;
	religiousAffiliation: ReferenceData;
	coedStatus: ReferenceData;
	collegeSizeLong: string;
	collegeSizeShort: string;
	historicallyBlackCollege: boolean;
	hispanicServingInstitution: boolean;
	hasYouVisitVirtualTour: boolean;
	campusReelActive: boolean;
	urlForCampusReel: string;
};

// keep separate from service response for future fields
export type CollegeCostServiceResponse = {
	annualInStateTuitionAndFees: number;
	annualOutOfStateTuitionAndFees: number;
	annualInDistrictTuitionAndFees: number;
	annualOutOfDistrictTuitionAndFees: number;
	annualRoomAndBoard: number;
	annualBooksAndSupplies: number;
	averageNetPrice: number;
	averageNetPriceIncomeUnder30K;
	averageNetPriceIncome30KTo48K;
	averageNetPriceIncome48KTo75K;
	averageNetPriceIncome75KTo110K;
	averageNetPriceIncomeOver110K;
	averageStudentLoanAmountPerYear: number;
	medianMonthlyLoanPaymentAfterGraduation: number;
	medianStudentLoanDebtAtGraduation: number;
	averagePercentStudentsReceivingGrants: number;
	averageGrantAmountPerYear: number;
	averageGrantScholarshipForFamilyIncomeLevelUnder30K: number;
	averageGrantScholarshipForFamilyIncomeLevel30K48K: number;
	averageGrantScholarshipForFamilyIncomeLevel48K75K: number;
	averageGrantScholarshipForFamilyIncomeLevel75K110K: number;
	averageGrantScholarshipForFamilyIncomeLevelOver110K: number;
	averagePercentOfStudentsTakingLoans: number;
	averagePercentOfNeedMet: number;
	inDistrictPerCreditHour: number;
	inStatePerCreditHour: number;
	outOfDistrictPerCreditHour: number;
};

export type CollegeCost = {
	annualInStateTuitionAndFees: number;
	annualOutOfStateTuitionAndFees: number;
	annualInDistrictTuitionAndFees: number;
	annualOutOfDistrictTuitionAndFees: number;
	annualRoomAndBoard: number;
	annualBooksAndSupplies: number;
	averageNetPrice: number;
	averageNetPriceIncomeUnder30K;
	averageNetPriceIncome30KTo48K;
	averageNetPriceIncome48KTo75K;
	averageNetPriceIncome75KTo110K;
	averageNetPriceIncomeOver110K;
	averageStudentLoanAmountPerYear: number;
	medianMonthlyLoanPaymentAfterGraduation: number;
	medianStudentLoanDebtAtGraduation: number;
	averagePercentStudentsReceivingGrants: number;
	averageGrantAmountPerYear: number;
	averageGrantScholarshipForFamilyIncomeLevelUnder30K: number;
	averageGrantScholarshipForFamilyIncomeLevel30K48K: number;
	averageGrantScholarshipForFamilyIncomeLevel48K75K: number;
	averageGrantScholarshipForFamilyIncomeLevel75K110K: number;
	averageGrantScholarshipForFamilyIncomeLevelOver110K: number;
	averagePercentOfStudentsTakingLoans: number;
	averagePercentOfNeedMet: number;
	inDistrictPerCreditHour: number;
	inStatePerCreditHour: number;
	outOfDistrictPerCreditHour: number;
};

export type CollegeMajorLevel = {
	numberOfMajors: number;
	associatesDegreeMajors: ReferenceData[];
	bachelorsDegreeMajors: ReferenceData[];
	mastersDegreeMajors: ReferenceData[];
	doctorateDegreeMajors: ReferenceData[];
};

type CollegeMajorLevelServiceResponse = {
	numberOfMajors: number;
	associatesDegreeMajors: ReferenceData[];
	bachelorsDegreeMajors: ReferenceData[];
	mastersDegreeMajors: ReferenceData[];
	doctorateDegreeMajors: ReferenceData[];
};

export type CollegeStudent = {
	numberTotalStudents: number;
	numberUndergraduateStudents: number;
	numberGraduateStudents: number;
	numberStatesRepresented: number;
	numberCountriesRepresented: number;
	percentStudentsIncomeBracket30k: number;
	percentStudentsIncomeBracket30k48k: number;
	percentStudentsIncomeBracket49k75k: number;
	percentStudentsIncomeBracket76k110k: number;
	percentStudentsIncomeBracket110k: number;
	percentUndergraduatesFirstGeneration: number;
	percentUndergraduateInState: number;
	percentUndergraduateOutOfState: number;
	percentUndergraduateMale: number;
	percentUndergraduateFemale: number;
	percentUndergraduateAsianPacificIslander: number;
	percentUndergraduateAfricanAmerican: number;
	percentUndergraduateEthnicityUnknown: number;
	percentUndergraduateHispanic: number;
	percentUndergraduateMultiRacial: number;
	percentUndergraduateNativeAmerican: number;
	percentUndergraduateNonResidentAlien: number;
	percentUndergraduateWhite: number;
	percentPartTimeUndergrads: number;
	percentUndergradsOver25: number;
	numberOnlineStudents: number;
};

type CollegeStudentServiceResponse = {
	numberTotalStudents: number;
	numberUndergraduateStudents: number;
	numberGraduateStudents: number;
	numberStatesRepresented: number;
	numberCountriesRepresented: number;
	percentStudentsIncomeBracket30k: number;
	percentStudentsIncomeBracket30k48k: number;
	percentStudentsIncomeBracket49k75k: number;
	percentStudentsIncomeBracket76k110k: number;
	percentStudentsIncomeBracket110k: number;
	percentUndergraduatesFirstGeneration: number;
	percentUndergraduateInState: number;
	percentUndergraduateOutOfState: number;
	percentUndergraduateMale: number;
	percentUndergraduateFemale: number;
	percentUndergraduateAsianPacificIslander: number;
	percentUndergraduateAfricanAmerican: number;
	percentUndergraduateEthnicityUnknown: number;
	percentUndergraduateHispanic: number;
	percentUndergraduateMultiRacial: number;
	percentUndergraduateNativeAmerican: number;
	percentUndergraduateNonResidentAlien: number;
	percentUndergraduateWhite: number;
	percentPartTimeUndergrads: number;
	percentUndergradsOver25: number;
	numberOnlineStudents: number;
};

export type AfterCollege = {
	averageSalaryWithSixYearsExperience: number;
};

type AfterCollegeServiceResponse = {
	averageSalaryWithSixYearsExperience: number;
};

export type CollegeAcademics = {
	studentToFacultyRatio: string;
	fourYearUndergraduateGraduationRate: number;
	sixYearUndergraduateGraduationRate: number;
	offersCareerServices: boolean;
	offersCreditForLifeExperience: boolean;
	offersOnCampusDaycare: boolean;
	offersRemedialServices: boolean;
	offersCreditForMilitaryTraining: boolean;
	dedicatedVeteranStaffMember: boolean;
	studentVeteranOrganization: boolean;
	yellowRibbonProgramMember: boolean;
	serviceMemberOpportunity: boolean;
};

type CollegeAcademicsServiceResponse = {
	studentToFacultyRatio: string;
	fourYearUndergraduateGraduationRate: number;
	sixYearUndergraduateGraduationRate: number;
	offersCareerServices: boolean;
	offersCreditForLifeExperience: boolean;
	offersOnCampusDaycare: boolean;
	offersRemedialServices: boolean;
	offersCreditForMilitaryTraining: boolean;
	dedicatedVeteranStaffMember: boolean;
	studentVeteranOrganization: boolean;
	yellowRibbonProgramMember: boolean;
	serviceMemberOpportunity: boolean;
};

export type CollegeAcademicPrograms = {
	onlineClasses: boolean;
	eveningWeekend: boolean;
};

type CollegeAcademicProgramsServiceResponse = {
	onlineClasses: boolean;
	eveningWeekend: boolean;
};

export type GradContentBlock = ContentBlock & { schoolIds?: string[]; degreeTypeIds?: string[] };

type CollegeContentBlocks = {
	insideScoop?: ContentBlock[];
	overviewTab?: ContentBlock[];
	admissionsTab?: ContentBlock[];
	costTab?: ContentBlock[];
	majorsTab?: ContentBlock[];
	gradProfilePage?: GradContentBlock[];
};

type CollegeContentBlocksServiceResponse = {
	insideScoop?: ContentBlock[];
	overviewTab?: ContentBlock[];
	admissionsTab?: ContentBlock[];
	costTab?: ContentBlock[];
	majorsTab?: ContentBlock[];
};

export type GraduateIndustry = {
	industry: ReferenceData;
	percent: number;
};

export type CollegeGraduateProgram = {
	gradType: ReferenceData;
	gradProgram: ReferenceData;
	online: boolean;
	programURL: string;
	collegeSchoolId?: string;
	tuitionBreakdownURL: string;
	scholarshipURL: string;
	applicationURL: string;
	inStateTuition: number;
	outOfStateTuition: number;
	testScoreRequired: boolean;
	greAccepted: boolean;
	gmatAccepted: boolean;
	lsatAccepted: boolean;
	mcatAccepted: boolean;
	requiredNumberOfEssays: number;
	requiredNumberOfLOR: number;
	averageGRETotal: number;
	minGRETotal: number;
	minGREVerbal: number;
	minGREWriting: number;
	minGREQuantitative: number;
	averageGMAT: number;
	minGMATTotal: number;
	averageLSAT: number;
	minLSAT: number;
	averageMCAT: number;
	minMCAT: number;
	averageGPA: number;
	minGPA: number;
	minYearsWork: number;
	percentFemale: number;
	percentMale: number;
	percentInState: number;
	percentOutOfState: number;
	percentInternational: number;
	jobOfferRate: number;
	offerAcceptanceRate: number;
	averageSalary: number;
	averageSigningBonus: number;
	industry: GraduateIndustry[];
};

export interface MatchDirectInfo {
	studentMatchesCollege: boolean;
}

export interface MeritAidAwardType {
	id: string;
	value: string;
}

export interface MeritAidRenewable {
	id: string;
	value: string;
}

export interface MeritAidAudienceStates {
	id: string;
	value: string;
}

export interface MeritAidCriteria {
	minAct?: number;
	minSat?: number;
	minGpa?: number;
}

export type CollegeMeritAid = {
	id: number;
	name: string;
	minAward?: number;
	maxAward?: number;
	awardType?: MeritAidAwardType;
	renewable?: MeritAidRenewable;
	meritAidAudienceStates?: MeritAidAudienceStates[];
	meritAidCriteria?: MeritAidCriteria[];
};

export type CollegeSchool = {
	collegeSchoolLogo?: string;
	collegeSchoolMajor?: { cipCode?: string; toRemove?: boolean }[];
	collegeSchoolName?: string;
	collegeSchoolUrl?: string;
	id: string;
	school: { id: string; value: string };
};

export type CollegeAcademicFaculty = {
	numberMaleFaculty?: number;
	numberFemaleFaculty?: number;
};

export type CompleteCollege = {
	id: number;
	college: College;
	collegeAssets: CollegeAssets;
	collegeCustomText: string;
	collegeAdmission: CollegeAdmission;
	collegeInformational: CollegeInformational;
	collegeCost: CollegeCost;
	collegeMajorLevel: CollegeMajorLevel;
	collegeAreaOfStudyList: ReferenceData[];
	collegeStudent: CollegeStudent;
	collegeMeritAid: CollegeMeritAid[];
	afterCollege: AfterCollege;
	consents: ReferenceData[];
	collegeAcademics: CollegeAcademics;
	collegeAcademicPrograms: CollegeAcademicPrograms;
	collegeAcademicFaculty: CollegeAcademicFaculty;
	contentBlocks: CollegeContentBlocks;
	collegeSchool: CollegeSchool[];
	postgradAdmission: CollegeGraduateProgram[];
	matchDirectInfo: MatchDirectInfo;
};

type CollegeAssetsServiceResponse = {
	[CollegeAssetsKey.COLLEGE_LOGO]: CollegeAsset[];
	[CollegeAssetsKey.COLLEGE_HERO]: CollegeAsset[];
	[CollegeAssetsKey.COLLEGE_VERTICAL_IMAGE]: CollegeAsset[];
};

type CollegeCustomTextServiceResponse = {
	overviewParagraph: string;
};

type CollegeAdmissionServiceResponse = {
	averageOverallAcceptanceRate: number;
	admittedStudentAverageGpa: number;
	admittedStudentAverageAct: number;
	admittedStudentAverageSat: number;
	admittedStudentsAct25thPercentile: number;
	admittedStudentsAct75thPercentile: number;
	admittedStudentsNewSatMath25thPercentile: number;
	admittedStudentsNewSatMath75thPercentile: number;
	admittedStudentsNewSatEbrw25thPercentile: number;
	admittedStudentsNewSatEbrw75thPercentile: number;
	rollingAdmissions: boolean;
	regularDecisionFallApplicationDeadline: string;
	earlyDecisionApplicationDeadline: string;
	earlyActionApplicationDeadline: string;
	testScoresOptional: boolean;
	satSubjectTestsRequired: boolean;
	highSchoolTranscriptRequired: boolean;
	lettersOfRecommendationsRequired: boolean;
	supplementalEssayPersonalStatementRequirement: boolean;
	interviewRequirement: boolean;
	applicationFeeWaiverOffered: boolean;
	undergraduateApplicationFee: number;
	hasOwnHomegrownApplication: boolean;
	acceptsCoalitionApplication: boolean;
	acceptsCommonApplication: boolean;
	acceptsStateSpecificApplicationSystem: boolean;
	acceptsUniversalCollegeApplication: boolean;
	easyApplyUrl: string;
	transferPortalUrl: string;
};

export type CollegePostGradAdmissionsServiceResponse = {
	gradType: ReferenceData;
	gradProgram: ReferenceData;
	online: boolean;
	programURL: string;
	tuitionBreakdownURL: string;
	scholarshipURL: string;
	applicationURL: string;
	inStateTuition: number;
	outOfStateTuition: number;
	testScoreRequired: boolean;
	greAccepted: boolean;
	gmatAccepted: boolean;
	lsatAccepted: boolean;
	mcatAccepted: boolean;
	requiredNumberOfEssays: number;
	requiredNumberOfLOR: number;
	averageGRETotal: number;
	minGRETotal: number;
	minGREVerbal: number;
	minGREWriting: number;
	minGREQuantitative: number;
	averageGMAT: number;
	minGMATTotal: number;
	averageLSAT: number;
	minLSAT: number;
	averageMCAT: number;
	minMCAT: number;
	averageGPA: number;
	minGPA: number;
	minYearsWork: number;
	percentFemale: number;
	percentMale: number;
	percentInState: number;
	percentOutOfState: number;
	percentInternational: number;
	jobOfferRate: number;
	offerAcceptanceRate: number;
	averageSalary: number;
	averageSigningBonus: number;
	industry: GraduateIndustry[];
};

export type CompleteCollegeServiceResponse = {
	id: number;
	college: College;
	collegeAssets: CollegeAssetsServiceResponse;
	collegeCustomText: CollegeCustomTextServiceResponse;
	collegeAdmissionV3: CollegeAdmissionServiceResponse;
	collegeInformational: CollegeInformationalServiceResponse;
	collegeCost: CollegeCostServiceResponse;
	collegeMajorLevel: CollegeMajorLevelServiceResponse;
	collegeAreaOfStudyList: ReferenceData[];
	collegeStudent: CollegeStudentServiceResponse;
	afterCollege: AfterCollegeServiceResponse;
	collegeAcademics: CollegeAcademicsServiceResponse;
	collegeAcademicPrograms: CollegeAcademicProgramsServiceResponse;
	contentBlocks: CollegeContentBlocksServiceResponse;
	postgradAdmission: CollegePostGradAdmissionsServiceResponse[];
};

export const emptyCollege = {
	ipedsId: undefined,
	name: undefined,
	city: undefined,
	country: undefined,
	state: undefined,
	zipCode: undefined,
	tagline: undefined,
};

export const emptyCollegeAdmission = {
	averageOverallAcceptanceRate: undefined,
	admittedStudentAverageGpa: undefined,
	admittedStudentAverageAct: undefined,
	admittedStudentAverageSat: undefined,
	admittedStudentsAct25thPercentile: undefined,
	admittedStudentsAct75thPercentile: undefined,
	admittedStudentsNewSatMath25thPercentile: undefined,
	admittedStudentsNewSatMath75thPercentile: undefined,
	admittedStudentsNewSatEbrw25thPercentile: undefined,
	admittedStudentsNewSatEbrw75thPercentile: undefined,
	rollingAdmissions: undefined,
	regularDecisionFallApplicationDeadline: undefined,
	earlyDecisionApplicationDeadline: undefined,
	earlyActionApplicationDeadline: undefined,
	testScoresOptional: undefined,
	satSubjectTestsRequired: undefined,
	highSchoolTranscriptRequired: undefined,
	lettersOfRecommendationsRequired: undefined,
	supplementalEssayPersonalStatementRequirement: undefined,
	interviewRequirement: undefined,
	applicationFeeWaiverOffered: undefined,
	undergraduateApplicationFee: undefined,
	hasOwnHomegrownApplication: undefined,
	acceptsCoalitionApplication: undefined,
	acceptsCommonApplication: undefined,
	acceptsStateSpecificApplicationSystem: undefined,
	acceptsUniversalCollegeApplication: undefined,
	easyApplyUrl: undefined,
	transferPortalUrl: undefined,
};

export const emptyCollegeInformational = {
	institutionType: EMPTY_REFERENCE,
	levelOfInstitution: EMPTY_REFERENCE,
	townSize: EMPTY_REFERENCE,
	religiousAffiliation: EMPTY_REFERENCE,
	coedStatus: EMPTY_REFERENCE,
	collegeSizeLong: undefined,
	collegeSizeShort: undefined,
	historicallyBlackCollege: undefined,
	hispanicServingInstitution: undefined,
	hasYouVisitVirtualTour: undefined,
	campusReelActive: undefined,
	urlForCampusReel: undefined,
};

export const emptyCollegeCost = {
	annualInStateTuitionAndFees: undefined,
	annualOutOfStateTuitionAndFees: undefined,
	annualInDistrictTuitionAndFees: undefined,
	annualOutOfDistrictTuitionAndFees: undefined,
	annualRoomAndBoard: undefined,
	annualBooksAndSupplies: undefined,
	averageNetPrice: undefined,
	averageNetPriceIncomeUnder30K: undefined,
	averageNetPriceIncome30KTo48K: undefined,
	averageNetPriceIncome48KTo75K: undefined,
	averageNetPriceIncome75KTo110K: undefined,
	averageNetPriceIncomeOver110K: undefined,
	averageStudentLoanAmountPerYear: undefined,
	medianMonthlyLoanPaymentAfterGraduation: undefined,
	medianStudentLoanDebtAtGraduation: undefined,
	averagePercentStudentsReceivingGrants: undefined,
	averageGrantAmountPerYear: undefined,
	averageGrantScholarshipForFamilyIncomeLevelUnder30K: undefined,
	averageGrantScholarshipForFamilyIncomeLevel30K48K: undefined,
	averageGrantScholarshipForFamilyIncomeLevel48K75K: undefined,
	averageGrantScholarshipForFamilyIncomeLevel75K110K: undefined,
	averageGrantScholarshipForFamilyIncomeLevelOver110K: undefined,
	averagePercentOfStudentsTakingLoans: undefined,
	averagePercentOfNeedMet: undefined,
	inDistrictPerCreditHour: undefined,
	inStatePerCreditHour: undefined,
	outOfDistrictPerCreditHour: undefined,
};

export const emptyCollegeMajorLevel = {
	numberOfMajors: undefined,
	associatesDegreeMajors: undefined,
	bachelorsDegreeMajors: undefined,
	mastersDegreeMajors: undefined,
	doctorateDegreeMajors: undefined,
};

export const emptyCollegeStudent = {
	numberTotalStudents: undefined,
	numberUndergraduateStudents: undefined,
	numberGraduateStudents: undefined,
	numberStatesRepresented: undefined,
	numberCountriesRepresented: undefined,
	percentStudentsIncomeBracket30k: undefined,
	percentStudentsIncomeBracket30k48k: undefined,
	percentStudentsIncomeBracket49k75k: undefined,
	percentStudentsIncomeBracket76k110k: undefined,
	percentStudentsIncomeBracket110k: undefined,
	percentUndergraduatesFirstGeneration: undefined,
	percentUndergraduateInState: undefined,
	percentUndergraduateOutOfState: undefined,
	percentUndergraduateMale: undefined,
	percentUndergraduateFemale: undefined,
	percentUndergraduateAsianPacificIslander: undefined,
	percentUndergraduateAfricanAmerican: undefined,
	percentUndergraduateEthnicityUnknown: undefined,
	percentUndergraduateHispanic: undefined,
	percentUndergraduateMultiRacial: undefined,
	percentUndergraduateNativeAmerican: undefined,
	percentUndergraduateNonResidentAlien: undefined,
	percentUndergraduateWhite: undefined,
	percentPartTimeUndergrads: undefined,
	percentUndergradsOver25: undefined,
	numberOnlineStudents: undefined,
};

export const emptyAfterCollege = {
	averageSalaryWithSixYearsExperience: undefined,
};

export const emptyCollegeAcademics = {
	studentToFacultyRatio: undefined,
	fourYearUndergraduateGraduationRate: undefined,
	sixYearUndergraduateGraduationRate: undefined,
	offersCareerServices: undefined,
	offersCreditForLifeExperience: undefined,
	offersOnCampusDaycare: undefined,
	offersRemedialServices: undefined,
	offersCreditForMilitaryTraining: undefined,
	dedicatedVeteranStaffMember: undefined,
	studentVeteranOrganization: undefined,
	yellowRibbonProgramMember: undefined,
	serviceMemberOpportunity: undefined,
};

export const emptyCollegeAcademicPrograms = {
	onlineClasses: undefined,
	eveningWeekend: undefined,
};

export const emptyCollegeAcademicFaculty: CollegeAcademicFaculty = {
	numberMaleFaculty: undefined,
	numberFemaleFaculty: undefined,
};

export const emptyCollegeContentBlocks: CollegeContentBlocks = {
	insideScoop: undefined,
	overviewTab: undefined,
	admissionsTab: undefined,
	costTab: undefined,
	majorsTab: undefined,
};

export const EMPTY_COMPLETE_COLLEGE: CompleteCollege = {
	id: undefined,
	college: undefined,
	collegeAssets: undefined,
	collegeCustomText: undefined,
	collegeAdmission: emptyCollegeAdmission,
	collegeInformational: emptyCollegeInformational,
	collegeCost: emptyCollegeCost,
	collegeMajorLevel: emptyCollegeMajorLevel,
	collegeAreaOfStudyList: undefined,
	collegeStudent: emptyCollegeStudent,
	afterCollege: emptyAfterCollege,
	consents: [],
	collegeAcademics: emptyCollegeAcademics,
	collegeAcademicPrograms: emptyCollegeAcademicPrograms,
	collegeAcademicFaculty: emptyCollegeAcademicFaculty,
	contentBlocks: emptyCollegeContentBlocks,
	postgradAdmission: [],
	collegeMeritAid: [],
	collegeSchool: [],
	matchDirectInfo: undefined,
};

export const EMPTY_POSTGRAD_ADMISSION: CollegeGraduateProgram = {
	gradType: null,
	gradProgram: null,
	programURL: null,
	applicationURL: null,
	online: false,
	inStateTuition: null,
	outOfStateTuition: null,
	tuitionBreakdownURL: null,
	scholarshipURL: null,
	testScoreRequired: true,
	greAccepted: false,
	gmatAccepted: true,
	lsatAccepted: false,
	mcatAccepted: false,
	minGRETotal: null,
	minGREQuantitative: null,
	minGREVerbal: null,
	minGREWriting: null,
	averageGRETotal: null,
	minGMATTotal: null,
	averageGMAT: null,
	minLSAT: null,
	averageLSAT: null,
	minMCAT: null,
	averageMCAT: null,
	minGPA: null,
	averageGPA: null,
	requiredNumberOfEssays: null,
	requiredNumberOfLOR: null,
	minYearsWork: null,
	percentFemale: null,
	percentMale: null,
	percentInState: null,
	percentOutOfState: null,
	percentInternational: null,
	averageSalary: null,
	averageSigningBonus: null,
	jobOfferRate: null,
	offerAcceptanceRate: null,
	industry: [],
	collegeSchoolId: null,
};

export const getEmptyCollegeAssets = (): CollegeAssets => ({
	[CollegeAssetsKey.COLLEGE_LOGO]: {
		id: undefined,
		collegeId: undefined,
		externalFilePath: '',
		identifier: '',
		collegeAssetTypeEnum: CollegeAssetsKey.COLLEGE_LOGO,
		attributionRequired: false,
	},
	[CollegeAssetsKey.COLLEGE_SCHOOL_LOGO]: [
		{
			id: undefined,
			collegeId: undefined,
			externalFilePath: '',
			identifier: '',
			collegeAssetTypeEnum: CollegeAssetsKey.COLLEGE_SCHOOL_LOGO,
			attributionRequired: false,
		},
	],

	[CollegeAssetsKey.COLLEGE_HERO]: {
		id: undefined,
		collegeId: undefined,
		externalFilePath: '',
		identifier: '',
		collegeAssetTypeEnum: CollegeAssetsKey.COLLEGE_HERO,
		attributionRequired: false,
	},

	[CollegeAssetsKey.COLLEGE_VERTICAL_IMAGE]: {
		id: undefined,
		collegeId: undefined,
		externalFilePath: '',
		identifier: '',
		collegeAssetTypeEnum: CollegeAssetsKey.COLLEGE_VERTICAL_IMAGE,
		attributionRequired: false,
	},
});

export const getCollegeAssetsFromCompleteCollege = (
	completeCollege: CompleteCollege | CompleteCollegeServiceResponse,
	isConvertingFromResponse = false
): CollegeAssets => {
	const collegeAssets = getEmptyCollegeAssets();

	if (R.isNil(completeCollege)) {
		return collegeAssets;
	}

	const assets = completeCollege.collegeAssets;
	if (R.isNil(assets) || R.isEmpty(assets)) {
		return collegeAssets;
	}
	const collegeLogoResponse = assets[CollegeAssetsKey.COLLEGE_LOGO];
	if (!R.isNil(collegeLogoResponse)) {
		collegeAssets[CollegeAssetsKey.COLLEGE_LOGO] = isConvertingFromResponse
			? collegeLogoResponse[0]
			: collegeLogoResponse;
	}

	const collegeSchoolLogoResponse = assets[CollegeAssetsKey.COLLEGE_SCHOOL_LOGO];
	if (!R.isNil(collegeSchoolLogoResponse)) {
		collegeAssets[CollegeAssetsKey.COLLEGE_SCHOOL_LOGO] = collegeSchoolLogoResponse;
	}

	const collegeHeroResponse = assets[CollegeAssetsKey.COLLEGE_HERO];
	if (!R.isNil(collegeHeroResponse)) {
		collegeAssets[CollegeAssetsKey.COLLEGE_HERO] = isConvertingFromResponse
			? collegeHeroResponse[0]
			: collegeHeroResponse;
	}

	const collegeVerticalImageResponse = assets[CollegeAssetsKey.COLLEGE_VERTICAL_IMAGE];
	if (!R.isNil(collegeVerticalImageResponse)) {
		collegeAssets[CollegeAssetsKey.COLLEGE_VERTICAL_IMAGE] = isConvertingFromResponse
			? collegeVerticalImageResponse[0]
			: collegeVerticalImageResponse;
	}

	return collegeAssets;
};

export const getCollegeAssetSrc = (
	completeCollege: CompleteCollege,
	collegeAssetKey: CollegeAssetsKey
): string => {
	const collegeAssets = getCollegeAssetsFromCompleteCollege(completeCollege);
	if (R.isNil(collegeAssets)) {
		return '';
	}

	const collegeAsset = collegeAssets[collegeAssetKey];

	if (R.isNil(collegeAsset)) {
		return '';
	}

	return Array.isArray(collegeAsset)
		? collegeAsset[0]?.externalFilePath
		: collegeAsset.externalFilePath;
};

export const convertOverviewParagraphResponse = (
	completeCollege: CompleteCollegeServiceResponse
): string => {
	if (R.isNil(completeCollege)) {
		return undefined;
	}

	const { collegeCustomText } = completeCollege;
	if (R.isNil(collegeCustomText)) {
		return undefined;
	}

	return collegeCustomText.overviewParagraph;
};

const convertDate = R.ifElse(R.isNil, R.identity, getLocalTrueDate);
export const convertCollegeAdmissionResponse = (
	completeCollege: CompleteCollegeServiceResponse
): CollegeAdmission => {
	if (R.isNil(completeCollege)) {
		return emptyCollegeAdmission;
	}

	const admission = completeCollege.collegeAdmissionV3;
	if (R.isNil(admission)) {
		return emptyCollegeAdmission;
	}

	return R.evolve(
		{
			regularDecisionFallApplicationDeadline: convertDate,
			earlyDecisionApplicationDeadline: convertDate,
			earlyActionApplicationDeadline: convertDate,
		},
		admission
	);
};

export const convertCollegeInformationalResponse = (
	completeCollege: CompleteCollegeServiceResponse
): CollegeInformational => {
	if (R.isNil(completeCollege)) {
		return emptyCollegeInformational;
	}

	const { collegeInformational } = completeCollege;
	if (R.isNil(collegeInformational)) {
		return emptyCollegeInformational;
	}

	const { institutionType } = collegeInformational;
	if (R.isNil(institutionType) || R.isNil(institutionType.value)) {
		collegeInformational.institutionType = emptyCollegeInformational.institutionType;
	}

	const { levelOfInstitution } = collegeInformational;
	if (R.isNil(levelOfInstitution) || R.isEmpty(levelOfInstitution.value)) {
		collegeInformational.levelOfInstitution = emptyCollegeInformational.levelOfInstitution;
	}

	const { townSize } = collegeInformational;
	if (R.isNil(townSize) || R.isEmpty(townSize.value)) {
		collegeInformational.townSize = emptyCollegeInformational.townSize;
	}

	return {
		institutionType: collegeInformational.institutionType,
		levelOfInstitution: collegeInformational.levelOfInstitution,
		townSize: collegeInformational.townSize,
		religiousAffiliation: collegeInformational.religiousAffiliation,
		coedStatus: collegeInformational.coedStatus,
		collegeSizeLong: collegeInformational.collegeSizeLong,
		collegeSizeShort: collegeInformational.collegeSizeShort,
		historicallyBlackCollege: collegeInformational.historicallyBlackCollege,
		hispanicServingInstitution: collegeInformational.hispanicServingInstitution,
		hasYouVisitVirtualTour: collegeInformational.hasYouVisitVirtualTour,
		campusReelActive: collegeInformational.campusReelActive,
		urlForCampusReel: collegeInformational.urlForCampusReel,
	};
};

export const isTwoYearCollege = (collegeInformational: CollegeInformational) => {
	if (!collegeInformational) {
		return false;
	}

	const { levelOfInstitution } = collegeInformational;
	if (!levelOfInstitution || !levelOfInstitution.value) {
		return false;
	}

	return levelOfInstitution.value.includes('2');
};

export const isFourYearCollege = (collegeInformational: CollegeInformational) =>
	!isTwoYearCollege(collegeInformational);

export const isPrivateCollege = (collegeInformational: CollegeInformational) => {
	if (!collegeInformational) {
		return false;
	}

	const { institutionType } = collegeInformational;

	return (
		institutionType &&
		institutionType.value &&
		institutionType.value.toLowerCase().includes('private')
	);
};

export const isPublicCollege = (collegeInformational: CollegeInformational) =>
	!isPrivateCollege(collegeInformational);

const isEmptyCollegeReferenceData = (collegeReferenceData: ReferenceData) => {
	if (R.isNil(collegeReferenceData)) {
		return true;
	}

	if (R.isNil(collegeReferenceData.value)) {
		return true;
	}

	return false;
};

export const isEmptyInstitutionTypeData = (collegeInformational: CollegeInformational) => {
	if (R.isNil(collegeInformational)) {
		return true;
	}
	return isEmptyCollegeReferenceData(collegeInformational.institutionType);
};

export const isEmptyLevelOfInstitutionData = (collegeInformational: CollegeInformational) => {
	if (R.isNil(collegeInformational)) {
		return true;
	}
	return isEmptyCollegeReferenceData(collegeInformational.levelOfInstitution);
};

export const isInternationalCollege = (college: College) => {
	if (!college) {
		return false;
	}

	const { country } = college;
	return !!country && country.id !== USA_COUNTRY_ID;
};

export const getDefaultCollege = (list: CompleteCollege[], collegeId: number): CompleteCollege =>
	R.isEmpty(list) ? EMPTY_COMPLETE_COLLEGE : list.find(college => college.id === collegeId);
