import { ValidationState } from '../validation/form';
import * as R from 'ramda';
import { PredicateConditionUnion, PredicateFieldMap } from '@cappex/constants';

export type Predicate = {
	all?: Predicate[];
	any?: Predicate[];
	field?: string;
	condition?: PredicateConditionUnion | undefined;
	value?: any;
};

const mapField = (name: string): string => PredicateFieldMap[name] ?? name;

const equals = (field: string, value: any) =>
	R.pipe(
		R.ifElse(
			formValue => typeof formValue === 'boolean' || typeof formValue === 'number',
			formValue => formValue.toString(),
			formValue => formValue
		),
		R.pathSatisfies<any, ValidationState>(
			R.isNil(value)
				? R.either(R.isNil, R.isEmpty)
				: R.equals(
						typeof value === 'boolean' || typeof value === 'number' ? value.toString() : value
				  ),
			[mapField(field), 'value', mapField(field)]
		)
	);

const contains = (field: string, value: any) =>
		R.pathSatisfies<any, ValidationState>(
			formValue => {
				if (R.isNil(value)) {
					return R.either(R.isNil, R.isEmpty)(formValue);
				}
				if (!Array.isArray(formValue)) {
					return false;
				}
				return R.includes(value.toString(), formValue);
			},
			// The path below determines the 'formValue' from above
			[mapField(field), 'value', mapField(field)]
	);

type ConditionMapper = {
	[k: string]: (field: string, value: any) => (formState: ValidationState) => boolean;
};

const conditionMapper: ConditionMapper = {
	EQUALS: equals,
	'NOT EQUALS': R.pipe(equals, R.complement),
	'CONTAINS': contains,
	'NOT CONTAINS': R.pipe(contains, R.complement)
};

const evaluatePredicate = (predicate: Predicate): R.SafePred<ValidationState> => {
	const allPredicate: R.SafePred<ValidationState> = R.allPass(
		predicate.all?.map(evaluatePredicate) ?? []
	);
	const anyPredicate: R.SafePred<ValidationState> = R.anyPass(
		predicate.any?.map(evaluatePredicate) ?? [R.T]
	);
	const basePredicate =
		conditionMapper[predicate.condition?.toUpperCase()]?.(predicate.field, predicate.value) ?? R.T;

	return R.allPass([allPredicate, anyPredicate, basePredicate]);
};

export default evaluatePredicate;
