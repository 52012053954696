import { VISITOR_TYPE_OPTIONS } from '@util/student/studentDataUtil';
import RadioChipSelector, {
	ChipColumnsDefinition,
	RadioOption,
} from '@common/components/RadioChipSelector';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormNames } from '@cappex/constants';
import getVtStMapping, {
	VisitorTypeToStudentTypeMappingResponse,
} from '@util/refdata/getVtStMapping';
import { FormContext } from '@util/validation/form';
import * as R from 'ramda';
import { BaseFormSelectProps } from './BaseFormSelect';

export type VisitorTypeSelectProps = {
	title?: string;
	options?: RadioOption[];
	chipColumns?: ChipColumnsDefinition;
	gutter?: boolean;
} & BaseFormSelectProps;

const EMPTY_FUNCTION = () => {};
const DEFAULT_CHIP_COLUMNS: ChipColumnsDefinition = { xs: 12 };

const VisitorTypeSelect: FC<VisitorTypeSelectProps> = ({
	title = 'What best describes you?',
	options = VISITOR_TYPE_OPTIONS,
	chipColumns = DEFAULT_CHIP_COLUMNS,
	gutter,
	required,
}) => {
	const { getFormValues } = useContext(FormContext);
	const { [FormNames.studentTypeId]: studentTypeId } = getFormValues();
	const [mapping, setMapping] = useState<VisitorTypeToStudentTypeMappingResponse[]>();

	useEffect(() => {
		getVtStMapping().then(maps => setMapping(maps));
	}, []);

	const visitorTypeOptions: RadioOption[] = useMemo(
		() =>
			(mapping || [])
				.filter(resp => resp.studentType === studentTypeId)
				.map(map => map.visitorType)
				.map(id => {
					const option = Object.values(options).find(item => item.value === id);
					return option ? { displayValue: option.displayValue, value: option.value } : null;
				})
				.filter(option => option !== null) as RadioOption[],
		[mapping, options, studentTypeId]
	);

	return (
		!R.isNil(studentTypeId) && (
			<RadioChipSelector
				title={title}
				options={visitorTypeOptions}
				radioName={FormNames.visitorTypeId}
				complete={EMPTY_FUNCTION}
				reverse={EMPTY_FUNCTION}
				chipColumns={chipColumns}
				gutter={gutter}
				required={required}
			/>
		)
	);
};

export default VisitorTypeSelect;
