import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../constants/types';
import RegisterButton from '@src/common/components/RegisterButton';
import request, {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	RequestSourceIdentifier,
} from '@cappex/request';
import getEndpoint from '@util/request';
import DataFlowContext from '../../util/DataFlowContext';
import { FormContext } from '@src/common/util/validation/form';
import checkLockout from '@src/common/util/lockout';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import DataFlowContainer from '../DataFlowContainer';
import {
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	makeStyles,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { styled, theme } from '@cappex/theme';
import AnchorButton from '@src/common/components/AnchorButton';
import EmailInput from '@common/components/EmailInput';
import { SubForm } from '@common/components/BaseValidationForm';
import PasswordInputValidation from '@common/components/PasswordInputValidation';
import SignInWithGoogleButton from '@src/common/components/SignInWithGoogleButton';
import * as R from 'ramda';
import { FormNames, ListFormNames } from '@cappex/constants';
import HiddenInput from '@src/common/components/HiddenInput';
import {
	GOOGLE_BUTTON_FIXED_WIDTH,
	GOOGLE_BUTTON_FIXED_WIDTH_PX,
	GOOGLE_BUTTON_FIXED_WIDTH_SMALL,
	GOOGLE_BUTTON_FIXED_WIDTH_SMALL_PX,
	GOOGLE_BUTTON_SMALL_BREAKPOINT,
} from '@src/common/constants/signInWithGoogle';
import getGoogleEmail from '@src/common/util/google/getGoogleEmail';
import getGoogleConsentText, {
	GoogleSSOConsentResponse,
} from '@src/common/util/google/getGoogleConsentText';
import FeatureFlag from '@src/features/environment/components/FeatureFlag';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import { QuizContext } from '@src/common/util/quiz';
import redirectToQuizResult from '../../util/redirectToQuizResult';
import {
	ConsentForm,
	ConsentResponse,
	ConsentType,
	updateConsent,
} from '@src/features/consent/consentUtil';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import OtpInput from './OtpInput';
import CssHideWhen from '@src/common/components/CssHideWhen';

const useStyles = makeStyles(() => ({
	fixedWidth: {
		maxWidth: GOOGLE_BUTTON_FIXED_WIDTH,
	},
}));

const widthStyles = `
  width: ${GOOGLE_BUTTON_FIXED_WIDTH};
  margin: 0 auto;
  @media (max-width: ${GOOGLE_BUTTON_SMALL_BREAKPOINT}) {
    width: ${GOOGLE_BUTTON_FIXED_WIDTH_SMALL};
  }
`;

const WidthGrid = styled(Grid)`
	${widthStyles}
`;

library.add(fal);

const PaddedBox = styled('div')`
	padding-left: 1rem;
	padding-right: 1rem;
`;
const SpacingDiv = styled('div')`
	padding-bottom: 2rem;
	text-align: center;
`;
const MarginDiv = styled('div')`
	margin-top: 1rem;
`;

const EdgeTopCheckbox = styled(Checkbox)`
	margin-top: -0.5rem;
`;

const TopAlignFormControlLabel = styled(FormControlLabel)`
	align-items: flex-start;
`;

const DEFAULT_FLAGS = {
	showEmailInput: true,
	showPasswordInput: false,
	showSignInWithGoogleButton: false,
	enableOtp: false,
};

const emailInUseError = 'This email address is already in use';

const FlexStepAccountCreation: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	active,
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		buttonConfig,
		legalTextConfig,
		showAlreadyHaveAccountLink,
		flags: {
			showEmailInput,
			showPasswordInput,
			showSignInWithGoogleButton,
			enableOtp,
		} = DEFAULT_FLAGS,
	},
	customLogoUrl,
	redirectIfAccountExists,
}) => {
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [consent, setConsent] = useState<GoogleSSOConsentResponse>();
	const [email, setEmail] = useState('');
	const [consentChecked, setConsentChecked] = useState(false);
	const [consentLoading, setConsentLoading] = useState(false);
	const [signInWithGoogleError, setSignInWithGoogleError] = useState('');
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { setFormErrors, getValue, setFormValue } = useContext(FormContext);
	const { getCurrentResult } = useContext(QuizContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const xxsDown = useMediaQuery(`(max-width: ${GOOGLE_BUTTON_SMALL_BREAKPOINT})`);
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));
	const buttonWidth = xxsDown ? GOOGLE_BUTTON_FIXED_WIDTH_SMALL_PX : GOOGLE_BUTTON_FIXED_WIDTH_PX;
	const classes = useStyles();
	const navigate = useNavigate();
	const navigationType = useNavigationType();
	const location = useLocation();
	const showOtpInput = location?.state?.showOtpInput;

	const { isAuthentic, forceRefresh } = useContext(AuthContext);

	const leftMediaProp = R.set(
		R.lensPath(['textProps', 'className']),
		classes.fixedWidth,
		leftMedia
	);

	const sendOtpEmail = async (emailAddress: string) => {
		await request<any>({
			url: getEndpoint('/public/otp/v1/request'),
			method: RequestMethod.POST,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
			data: { emailAddress },
		});
	};

	const onSignInWithGoogle = useCallback(
		async (credentials: string) => {
			setSignInWithGoogleError('');
			setSubmitDisabled(true);
			setConsentLoading(true);

			const googleEmail = await getGoogleEmail(credentials, err => {
				setSubmitDisabled(false);
				setConsentLoading(false);
				setSignInWithGoogleError(err);
			});
			if (!googleEmail) return;
			setEmail(googleEmail);

			const googleConsentText = await getGoogleConsentText(err => {
				setSubmitDisabled(false);
				setConsentLoading(false);
				setSignInWithGoogleError(err);
			});

			if (!googleConsentText) return;
			setConsent(googleConsentText);
			navigate(location.pathname);

			setSubmitDisabled(false);
			setConsentLoading(false);
		},
		[navigate, location.pathname]
	);

	useEffect(() => {
		if (redirectIfAccountExists && signInWithGoogleError === emailInUseError) {
			redirectToQuizResult(getCurrentResult);
		}
	}, [getCurrentResult, redirectIfAccountExists, signInWithGoogleError]);

	const handleConsentChecked = (checked: boolean) => {
		setConsentChecked(checked);

		const currentConsents =
			(getValue(ListFormNames.consents) as { consents: ConsentForm[] })?.consents || [];

		const updatedConsents = updateConsent({
			consents: currentConsents,
			consentTextId: consent.id,
			consentTypeId: ConsentType.GOOGLE_SSO,
			responseTypeId: checked ? ConsentResponse.YES : ConsentResponse.NO_RESPONSE,
		});

		setFormValue(ListFormNames.consents, updatedConsents);
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => async err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				const emailAlreadyInUse = errors?.email?.[0] === emailInUseError;
				if (emailAlreadyInUse) {
					if (redirectIfAccountExists) {
						redirectToQuizResult(getCurrentResult);
						return;
					}
					if (enableOtp) {
						sendOtpEmail(getValue(FormNames.email)[FormNames.email] as string);
						navigate(location.pathname + location.search, { state: { showOtpInput: true } });
						return;
					}
				}

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openSnackbar({
						message: errors[FORM_NAME],
					});
				}
			}
		});
		return true;
	};

	useEffect(() => {
		if (
			(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
			active
		) {
			if (navigationType === 'POP') {
				navigate(-1);
			} else {
				complete();
			}
			return;
		}

		if (navigationType === 'POP' && consent) {
			setConsent(undefined);
			setEmail('');
		}
	}, [location, navigationType, consent, isAuthentic, active, complete, navigate]);

	return (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			variant={variant}
			leftMedia={leftMediaProp}
			rightMedia={rightMedia}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			centered={smDown}
			customLogoUrl={customLogoUrl}
		>
			<CssHideWhen when={!showOtpInput}>
				<WidthGrid>
					<OtpInput
						onComplete={forceRefresh} // this will also refresh the page, effectively putting the user on the next step, since this step skips itself if the user is authenticated
					/>
				</WidthGrid>
			</CssHideWhen>

			<CssHideWhen when={showOtpInput}>
				{consent && (
					<>
						<SubForm name="accountInfo">
							<HiddenInput name={FormNames.email} initialValue={email} />
						</SubForm>
						<WidthGrid>
							<TopAlignFormControlLabel
								control={
									<EdgeTopCheckbox
										checked={consentChecked}
										onChange={(_, val) => handleConsentChecked(val)}
									/>
								}
								label={consent.text}
							/>
						</WidthGrid>
					</>
				)}
				{!consent && !consentLoading && (
					<WidthGrid container direction="column" spacing={3}>
						<SubForm name="accountInfo">
							{showEmailInput && <EmailInput disabled={!active} required={active} />}
							{showPasswordInput && (
								<PasswordInputValidation disabled={!active} required={active} />
							)}
						</SubForm>
					</WidthGrid>
				)}
				{consentLoading && (
					<WidthGrid container justifyContent="center">
						<SpacingDiv>
							<CircularProgress size={55} />
						</SpacingDiv>
					</WidthGrid>
				)}
				<WidthGrid>
					<RegisterButton
						md={12}
						submitDisabled={submitDisabled || (consent && !consentChecked)}
						onClick={onClick}
						size="large"
						smallGutter
					>
						{buttonConfig?.text ?? 'Next'}
						{buttonConfig?.icon && (
							<PaddedBox>
								<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
							</PaddedBox>
						)}
					</RegisterButton>
				</WidthGrid>

				{showSignInWithGoogleButton && !consent && (
					<FeatureFlag flag="enableGoogleSSO">
						<Grid container direction="column" alignItems="center">
							<Typography variant="body2" color="textSecondary" align="center">
								OR
							</Typography>
							<MarginDiv>
								<SignInWithGoogleButton
									text="signup_with"
									width={buttonWidth}
									onSignIn={onSignInWithGoogle}
								/>
								{signInWithGoogleError &&
									(!redirectIfAccountExists || signInWithGoogleError !== emailInUseError) && (
										<Typography variant="caption" color="error">
											{signInWithGoogleError}
										</Typography>
									)}
							</MarginDiv>
						</Grid>
					</FeatureFlag>
				)}
				{(legalTextConfig || showAlreadyHaveAccountLink) && (
					<WidthGrid>
						<SpacingDiv>
							{legalTextConfig && (
								<MarginDiv>
									<LegalTextAndLinks configs={legalTextConfig} />
								</MarginDiv>
							)}
							{showAlreadyHaveAccountLink && (
								<MarginDiv>
									<AnchorButton text="Already have an account?" href="/login" />
								</MarginDiv>
							)}
						</SpacingDiv>
					</WidthGrid>
				)}
			</CssHideWhen>
		</DataFlowContainer>
	);
};

export default FlexStepAccountCreation;
