import { FormNames } from '@cappex/constants';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { FormContext } from '@src/common/util/validation/form';
import React, { FC, useContext, useState } from 'react';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	WebResponse,
} from '@cappex/request';
import getEndpoint from '@util/request';
import OTPInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { styled } from '@cappex/theme';

const StyledTextField = styled(TextField)`
	width: 3.25rem;
	margin-bottom: 1rem;
`;

const Separator = styled.span`
	width: 0.75rem;
`;

interface OtpInputProps {
	onComplete: () => void;
}

const otpLength = 6;

const OtpInput: FC<OtpInputProps> = ({ onComplete }) => {
	const { getValue } = useContext(FormContext);
	const emailAddress = getValue(FormNames.email)[FormNames.email];
	const [otpCode, setOtp] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [disableResend, setDisableResend] = useState(false);
	const [isLockedOut, setIsLockedOut] = useState(false);

	const sendOtpEmail = () => {
		setDisableResend(true);
		request<any>({
			url: getEndpoint('/public/otp/v1/request'),
			method: RequestMethod.POST,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
			data: { emailAddress },
		});
	};

	const submitOtp = async (otp: string) => {
		setLoading(true);
		try {
			const { data } = await request<WebResponse<any, any>>({
				url: getEndpoint('/auth/v1/otp'),
				method: RequestMethod.POST,
				withCredentials: true,
				headers: [JsonAcceptHeader, JsonContentTypeHeader],
				data: { emailAddress, otpCode: otp },
			});

			if (data.meta.success) {
				onComplete();
			}
		} catch (e) {
			const responseMessage = e.response.data.response;
			if (responseMessage.includes('locked')) setIsLockedOut(true);
			setError(responseMessage);
		}

		setLoading(false);
		setDisableResend(false);
	};

	const handleChangeOtp = (otp: string) => {
		setOtp(otp);
		setError('');
		if (otp.length === otpLength) submitOtp(otp);
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography color="textPrimary" variant="h6">
					Enter the one-time code sent to your email.
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<OTPInput
					value={otpCode}
					onChange={handleChangeOtp}
					numInputs={otpLength}
					renderSeparator={() => <Separator />}
					renderInput={props => (
						<StyledTextField
							disabled={loading}
							error={!!error}
							inputProps={{ ...props }}
							variant="outlined"
						/>
					)}
				/>
				{error && (
					<Typography variant="caption" color="error">
						{error}
					</Typography>
				)}
			</Grid>

			<Grid item xs={12}>
				<Button
					onClick={() => submitOtp(otpCode)}
					disabled={loading || otpCode.length !== otpLength}
					variant="contained"
					fullWidth
					color="primary"
					size="large"
				>
					Next
				</Button>
			</Grid>

			<Grid item xs={12}>
				{isLockedOut ? (
					<Button component={Link} to="/forgot-password" fullWidth>
						Forgot password?
					</Button>
				) : (
					<Button onClick={sendOtpEmail} disabled={disableResend} fullWidth>
						Resend code
					</Button>
				)}
			</Grid>
		</Grid>
	);
};

export default OtpInput;
