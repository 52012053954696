import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { ConsentInputType, ConsentResponse, ConsentType } from '@src/features/consent/consentUtil';
import styled from 'styled-components';
import { FloatingCloseButton } from '@cappex/components';
import inquiryConsentSvg from '@common/assets/inquiry-consent.svg';
import ConsentInput from './ConsentInput';

const StyledDialog = styled(Dialog)({
	'& .MuiPaper-root': {
		margin: '8px',
		width: '100%',
		maxWidth: '34rem',
	},
});

const Padding = styled.div`
	padding: 3rem 1.5rem;
`;

const MaxWidthDiv = styled.div`
	max-width: 28rem;
`;

const StyledButton = styled(Button)`
	padding-left: 5rem;
	padding-right: 5rem;
	border-radius: 0.75rem;
`;

interface Props {
	complete: () => void;
	consentTypeId: ConsentType;
	consentTextId: string;
	consentText: string;
	consentInputTypeId: ConsentInputType;
}

const InquiryConsentModal: React.FC<Props> = ({
	complete,
	consentTypeId,
	consentTextId,
	consentText,
	consentInputTypeId,
}) => {
	const onSaveConsentDirectly = useCallback(
		async (consentPayload: {
			consentTextId?: string;
			consentTypeId?: ConsentType;
			responseTypeId: ConsentResponse;
			isValid?: boolean;
		}) => {
			const data = { consents: [consentPayload] };

			await request<any>({
				url: getEndpoint('/governance/consent/save'),
				method: RequestMethod.POST,
				withCredentials: true,
				headers: [JsonAcceptHeader, JsonContentTypeHeader],
				data,
			});
		},
		[]
	);

	return (
		<StyledDialog open maxWidth="sm" onClose={complete}>
			<FloatingCloseButton onClick={complete} />
			<Padding>
				<Grid container spacing={3} alignItems="center" direction="column">
					<Grid item>
						<Typography variant="h6">👋 Quick heads up!</Typography>
					</Grid>

					<Grid item>
						<MaxWidthDiv>
							<ConsentInput
								hasValidCorrespondingInput
								consentTextId={consentTextId}
								consentTypeId={consentTypeId}
								consentText={consentText}
								consentInputTypeId={consentInputTypeId}
								active
								mode="direct"
								onSaveConsentDirectly={onSaveConsentDirectly}
							/>
						</MaxWidthDiv>
					</Grid>

					<Grid item>
						<img src={inquiryConsentSvg} alt="Inquiry Consent" />
					</Grid>

					<Grid item>
						<StyledButton variant="contained" color="primary" onClick={complete}>
							Got it
						</StyledButton>
					</Grid>
				</Grid>
			</Padding>
		</StyledDialog>
	);
};

export default InquiryConsentModal;
