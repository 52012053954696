import { CompleteCollege } from '@util/college/collegeDataUtil';
import * as R from 'ramda';
import { WebResponse } from '@cappex/request';
import { FormKeyedData } from '@util/request';
import { ReferenceData } from '@src/common/util/hooks/useCloudReferenceData';

export interface ConsentForm {
	consentTypeId: ConsentType;
	consentTextId: string;
	responseTypeId: ConsentResponse;
}

export enum ConsentInputType {
	NOT_CONFIGURABLE = '1',
	DISCLAIMER = '2',
	CHECKBOX = '3',
}

export enum ConsentType {
	GDPR = '1',
	TCPA = '2',
	GOOGLE_SSO = '3',
	UNDERAGE = '5',
	INQUIRY_CONSENT = '6',
	TOUR_AI = '7',
}

export enum ConsentResponse {
	NO_RESPONSE = '1',
	YES = '2',
	NO = '3',
}

export enum StudentConsentResponse {
	NO_RESPONSE = 'NO_RESPONSE',
	YES = 'YES',
	NO = 'NO',
}

export interface CanObtainConsentRequest {
	consentTypeId: ConsentType;
	birthDate?: string;
	stateId?: string;
	zipCode?: string;
}

interface PositiveCanObtainConsentResponse {
	canObtainConsent: true;
	consentInputTypeId: ConsentInputType;
	consentTypeId: ConsentType;
	consentTextId: string;
	consentText: string;
}

interface NegativeCanObtainConsentResponse {
	canObtainConsent: false;
}

export type CanObtainConsentResponse =
	| PositiveCanObtainConsentResponse
	| NegativeCanObtainConsentResponse;

export type V1StudentCollegeConsentResponse = {
	id: number;
	studentId: string;
	collegeId: number;
	consentTypeId: number;
	studentConsentResponseType: StudentConsentResponse;
};

export type V1StudentCollegeConsentWebResponse = WebResponse<
	FormKeyedData,
	V1StudentCollegeConsentResponse
>;

export type V1StudentCollegeConsentRequestForm = {
	v1StudentCollegeConsents: V1StudentCollegeConsentResponse[];
};

export const CONSENT_TEXT_BEFORE_COLLEGE_NAMES = `With your consent, we'll share your information with`;
export const CONSENT_TEXT_AFTER_COLLEGE_NAMES = `so they may provide you further information about their programs.`;

const getCollegeNamesFormatted = (colleges: CompleteCollege[]): string => {
	const collegeNamesList = colleges.map(complete => complete.college.name);
	const collegeCount = collegeNamesList.length;

	if (collegeCount === 1) {
		return collegeNamesList[0];
	}
	if (collegeCount === 2) {
		return R.join(' and ', collegeNamesList);
	}
	if (collegeCount > 2) {
		// joins names with ', ' and adds ' and ' before the last one
		// eg college1, college2 and college3
		return R.join(' and ', [
			R.join(', ', R.dropLast(1, collegeNamesList)), // join all except last with ', '
			R.takeLast(1, collegeNamesList),
		]);
	}

	return '';
};

export const getConsentText = (colleges: CompleteCollege[]) =>
	R.isEmpty(colleges)
		? ''
		: `${CONSENT_TEXT_BEFORE_COLLEGE_NAMES} ${getCollegeNamesFormatted(
				colleges
		  )} ${CONSENT_TEXT_AFTER_COLLEGE_NAMES}`;

export const buildStudentConsentRequestFormData = (
	responseType: StudentConsentResponse,
	completeColleges: CompleteCollege[],
	consentType: ConsentType[] = []
): V1StudentCollegeConsentRequestForm => {
	const v1StudentCollegeConsents = [];

	// create request form data
	if (!R.isNil(completeColleges) && !R.isEmpty(completeColleges)) {
		completeColleges.forEach(({ consents, id }) => {
			// if we have multiple consents for a college of the correct type
			// create multiple response records
			if (!R.isNil(consents) && !R.isEmpty(consents)) {
				const consentRecordsToPush = R.pipe(
					R.map<ReferenceData, string>(R.prop('id')),
					R.filter<string, 'array'>(R.isEmpty(consentType) ? R.T : c => R.includes(c, consentType)),
					R.map(consentTypeId => ({
						collegeId: id,
						consentTypeId,
						studentConsentResponseType: responseType,
					}))
				)(consents);
				v1StudentCollegeConsents.push(consentRecordsToPush);
			}
		});
	}

	return {
		v1StudentCollegeConsents: R.flatten(v1StudentCollegeConsents),
	};
};

interface UpdateConsentOptions {
	consents: ConsentForm[];
	consentTextId: string;
	consentTypeId: ConsentType;
	responseTypeId: ConsentResponse;
	isValid?: boolean;
}

export const updateConsent = ({
	consents,
	consentTextId,
	consentTypeId,
	responseTypeId,
	isValid = true,
}: UpdateConsentOptions): ConsentForm[] => {
	const existingConsentIndex = consents.findIndex(
		consent => consent.consentTextId === consentTextId
	);

	if (existingConsentIndex !== -1) consents.splice(existingConsentIndex, 1);

	if (isValid) {
		consents.push({ consentTextId, consentTypeId, responseTypeId });
	}

	return [...consents];
};
