import React, { useContext, useEffect } from 'react';
import { ConsentForm, ConsentResponse, ConsentType, updateConsent } from '../consentUtil';
import { ListFormNames } from '@cappex/constants';
import { FormContext } from '@src/common/util/validation/form';
import styled from 'styled-components';
import { Markdown } from '@src/common/constants/Markdown';

const StyledMarkdown = styled(Markdown)`
	p {
		color: ${({ theme }) => theme.palette.ink.light};
	}
`;

interface Props {
	consentTextId?: string;
	consentTypeId?: ConsentType;
	consentText?: string;
	addInitialValueOnlyWhenActive?: boolean;
	active?: boolean;
	mode?: 'form' | 'direct';
	onSaveConsentDirectly?: (consentPayload: ConsentForm) => void;
}

const Disclaimer: React.FC<Props> = ({
	consentTextId,
	consentTypeId,
	consentText,
	addInitialValueOnlyWhenActive,
	active,
	mode,
	onSaveConsentDirectly,
}) => {
	const { getValue, setFormValue } = useContext(FormContext);

	useEffect(() => {
		if (addInitialValueOnlyWhenActive && !active) return;

		if (mode === 'direct' && onSaveConsentDirectly) {
			onSaveConsentDirectly({ consentTextId, consentTypeId, responseTypeId: ConsentResponse.YES });
			return;
		}

		const currentConsents =
			(getValue(ListFormNames.consents) as { consents: ConsentForm[] })?.consents || [];

		const updatedConsents = updateConsent({
			consents: currentConsents,
			consentTextId,
			consentTypeId,
			responseTypeId: ConsentResponse.YES,
		});

		setFormValue(ListFormNames.consents, updatedConsents);
	}, [
		getValue,
		setFormValue,
		consentTextId,
		consentTypeId,
		active,
		addInitialValueOnlyWhenActive,
		mode,
		onSaveConsentDirectly,
	]);

	return <StyledMarkdown variant="caption">{consentText}</StyledMarkdown>;
};

export default Disclaimer;
