import { FC, useEffect, useContext, useCallback, useState } from 'react';
import { QueueAction, ModalContext } from '@src/common/util/steps/components/ModalStepFlow';
import { useNavigate } from 'react-router-dom';
import { StepComponentProps } from '@src/common/util/steps';
import { DataFlowStepConfig } from '../../constants/types';
import AuthContext from '@src/common/util/auth';
import { env } from '@src/features/environment';
import { FormContext } from '@src/common/util/validation/form';
import { FormNames } from '@cappex/constants';
import InquiryConsentModal from '@src/features/consent/components/InquiryConsentModal';
import canObtainConsent from '@src/features/consent/utils/canObtainConsent';
import { ConsentType } from '@src/features/consent/consentUtil';
import { loadUserAccount } from '@src/common/util/user/userUtil';

// TODO: remove when CAPPEX-3554 is done
declare const newrelic: any;

const RedirectStep: FC<StepComponentProps<any, any, DataFlowStepConfig>> = ({ data, active }) => {
	const { queueModal } = useContext(ModalContext);
	const { attemptedLocation } = useContext(AuthContext);
	const { getValue } = useContext(FormContext);
	const [refreshedAuth, setRefreshedAuth] = useState(false);
	const navigate = useNavigate();
	const tourDataExternalId = getValue(FormNames.tourDataExternalId)[FormNames.tourDataExternalId];

	useEffect(() => {
		active &&
			canObtainConsent(ConsentType.INQUIRY_CONSENT, res => {
				res.canObtainConsent &&
					queueModal(QueueAction.PREPEND, 'inquiry_consent_modal', InquiryConsentModal, {
						consentTypeId: res.consentTypeId,
						consentTextId: res.consentTextId,
						consentText: res.consentText,
						consentInputTypeId: res.consentInputTypeId,
					});
			});
	}, [active, queueModal]);

	const onAuthFail = useCallback(() => {
		navigate('/login');

		// TODO: remove when CAPPEX-3554 is done
		if (env === 'prod') {
			const dataFlowCode = getValue(FormNames.dataFlowCode)[FormNames.dataFlowCode];
			const error = new Error(
				`Authentication error during redirect. DataFlowCode: ${dataFlowCode}`
			);

			if (typeof newrelic !== 'undefined') {
				newrelic.noticeError(error);
			} else {
				throw error;
			}
			throw error;
		}
	}, [navigate, getValue]);

	const { forceRefresh } = useContext(AuthContext);

	const refreshThenRedirect = useCallback(async () => {
		await forceRefresh(false, onAuthFail);
		navigate(attemptedLocation || (data as any).to, { replace: true });
	}, [forceRefresh, onAuthFail, navigate, attemptedLocation, data]);

	useEffect(() => {
		if (!active) return;
		if (tourDataExternalId) {
			loadUserAccount(
				account => {
					window.location.href = `${process.env.REACT_APP_VIRTUAL_TOUR_REDIRECT_URL}?yv_vt_id=${tourDataExternalId}&yv_cx_id=${account.accountUuid}`;
				},
				onAuthFail,
				onAuthFail
			);
			return;
		}
		if (!refreshedAuth) {
			refreshThenRedirect();
			setRefreshedAuth(true);
		}
	}, [active, refreshThenRedirect, refreshedAuth, tourDataExternalId, onAuthFail]);

	return null;
};
export default RedirectStep;
