import { FC, useContext, useEffect } from 'react';
import { StepComponentProps } from '@util/steps';
import request, { JsonContentTypeHeader, RequestMethod, WebResponse } from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import {
	COLLEGE_ADDED_MESSAGE,
	COLLEGE_COULD_NOT_BE_ADDED_MESSAGE,
	StudentCollegeConnectionType,
	StudentCollegeListStatus,
} from '@util/studentcollege/constants';
import StudentCollegeListContext from '@util/studentcollege/StudentCollegeListContext';
import { SnackbarContext } from '@common/components/SnackbarManager';
import { JitEvent } from '../util/jit/constants';
import EventBus, { EventTypes } from '@src/features/app/util/EventBus';

type Props = Omit<StepComponentProps, 'reverse'> & {
	collegeIdToAdd: string;
	externalLocation: string;
};

export type V1AddedCollegeResponse = {
	id: string;
};

export type V1AddedCollegeWebResponse = WebResponse<FormKeyedData, V1AddedCollegeResponse>;

export const AddCollegeToModalStepFlow: FC<Props> = ({
	complete,
	collegeIdToAdd,
	externalLocation,
}) => {
	const { refresh } = useContext(StudentCollegeListContext);
	const { openSnackbar } = useContext(SnackbarContext);

	useEffect(() => {
		const form = {
			collegeId: collegeIdToAdd,
			collegeListStatus: StudentCollegeListStatus.ACTIVE,
			collegeListConnectionType: StudentCollegeConnectionType.GENERAL,
			levelOfInterest: null,
			collegeApplicationStatus: null,
			externalLocation,
		};

		request<V1AddedCollegeWebResponse>({
			url: getEndpoint('/college-list/v1/external/create'),
			method: RequestMethod.POST,
			data: form,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		})
			.then(() => {
				EventBus.emit(EventTypes.JIT_CHECK, {
					eventType: JitEvent.COLLEGE_INQUIRY,
					collegeIds: [collegeIdToAdd],
				});

				refresh();
				openSnackbar({ message: COLLEGE_ADDED_MESSAGE });
				complete();
			})
			.catch(() => {
				throw new Error('Error adding college to list.');
				openSnackbar({ message: COLLEGE_COULD_NOT_BE_ADDED_MESSAGE });
			});
	}, [collegeIdToAdd, complete, externalLocation, openSnackbar, refresh]);

	return null;
};
